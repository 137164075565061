import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { getRoleOwnerDetailsapi, updateRoleAndOwnerApi } from "../../../apis/getRoleOwnerDetails";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay-ts";
import { Toast } from "primereact/toast";
import _, { isObject } from "lodash";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { SuggestionsMatch, showBackgroundProcess } from "../../../Utils/helpers";

function MassupdateDialog(props) {
  const { visible, setVisible, getProjectPlanApi, clearFilters } = props;
  const [roleOwnerList, setRoleOwnerList] = useState([]);
  const [owner, setOwner] = useState({});
  const [formDirty, setFormDirty] = useState(false);
  const projectDetails = useSelector((state) => state.ProjectPlanReducer);
  const [loader, setLoader] = useState(false);
  const [updateEnable, setUpdateEnable] = useState(false);
  const initialState = {
    isDefaultOwnersSelected: false,
    pm: "",
    selectedOwnerList: [],
    isDropdownSelected: false,
  };
  const [dropDownMatch,setDropDownMatch]=useState(true);
  const [ownerDetails, setOwnerDetails] = useState(initialState);
  const { Region, BU } = projectDetails?.projectPlanDesign[0] || {};
  const toast = useRef();
  const { ProjectID } = useParams();
  const [width, setWidth] = useState(0);
  const dropdownRef = useRef(null);
  useEffect(() => {
      if (dropdownRef?.current) {
        setWidth(dropdownRef?.current?.offsetWidth);
      }
  }, [roleOwnerList]);
  const setDefaultOwner = async () => {
    if (roleOwnerList && roleOwnerList?.RolesList?.length > 0) {
      const defaultObj = {};
      for (let [index, item] of roleOwnerList.RolesList.entries()) {
        if (item.DefaultOwner !== "") {
          defaultObj[index] = { pyName: item.DefaultOwner, Person: item.DefaultOwner };
        } else {
          defaultObj[index] = { pyName: "", Person: "" };
        }
      }
      setOwner(defaultObj);
    }
  };

  const getRoleOwnerDetails = async () => {
    setLoader(true);
    const response = await getRoleOwnerDetailsapi(ProjectID, BU, Region);
    setRoleOwnerList(response.data);
    if (response.data !== undefined) {
      setOwnerDetails({
        ...initialState,
        isDefaultOwnersSelected: response.data.IsDefaultOwnersSelected,
        pm: response.data.PM,
      });
    }
    // setChecked(response.data.IsDefaultOwnersSelected);
    setLoader(false);
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Record Update successfully",
      life: 3000,
    });
  };

  useEffect(() => {
    if (visible) {
      getRoleOwnerDetails();
    }
    return () => {
      setOwner({});
    };
  }, [visible]);

  useEffect(() => {
    if (!_.isEmpty(roleOwnerList)) {
      setDefaultOwner();
    }
  }, [roleOwnerList]);

  const payload = {
    BU: BU,
    Region: Region,
    AWMProjectID: ProjectID,
    PM: ownerDetails.pm,
    IsDefaultOwnersSelected: ownerDetails.isDefaultOwnersSelected,
    RoleOwnerList: [],
  };

  const updateObject = (value, index, defaultOwner) => {
    setUpdateEnable(true);
    // update ownerDetails only when there is some new value selected, do not update when either null, or an already selected value is same as selected value
    if(value !== null && defaultOwner !== value?.Person){
      setOwnerDetails((prevState) => ({
        ...prevState,
        isDropdownSelected: true,
        selectedOwnerList: {
          ...prevState.selectedOwnerList,
          [index]: value,
        },
      }));
      setOwner((prevOwner) => {
        const updatedOwner = { ...prevOwner };
        updatedOwner[index] = value;
        updatedOwner[index] = value;
        return updatedOwner;
      });
    }
  };

  useEffect(()=>{
      let isDirty=false;
      let keys=Object.keys(ownerDetails?.selectedOwnerList);
        keys?.forEach((key)=>{
          if(isObject(ownerDetails?.selectedOwnerList[key])){
            isDirty=true;
            return;
          }
        })
      setFormDirty(isDirty);

  },[ownerDetails])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    for (let key in ownerDetails.selectedOwnerList) {
      ownerDetails?.selectedOwnerList[key]?.Person && payload.RoleOwnerList.push({
        Role: roleOwnerList?.RolesList[key]?.Role,
        Owner: ownerDetails?.selectedOwnerList[key]?.Person,
      });
    }

    const response = await updateRoleAndOwnerApi(payload);
    if (response.status === 200) {
      setVisible(false);
      showSuccess();
    }
    setUpdateEnable(false);
    setLoader(false);
    getProjectPlanApi();
    clearFilters("MassUpdate Clicked");
    showBackgroundProcess(toast);
  };
  const shouldDisableUpdate = () => {
    let result = '';
    if (!updateEnable) {
      result = true;
    }
    else if (ownerDetails?.selectedOwnerList?.length === 0 && !ownerDetails?.isDropdownSelected && roleOwnerList?.RolesList?.every(role => role.DefaultOwner === '')) {
      result = true;
    }
    else { result = false; }

    return result;
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Mass Update"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => { setVisible(false); setUpdateEnable(false); }}
      >
        <LoadingOverlay active={loader} spinner text="">
          <form id="massUpdateForm">
            <Container>
              <div className="formWrapper">
                <Row>
                  <Col>
                    <h5>Role</h5>
                    {roleOwnerList &&
                      roleOwnerList?.RolesList?.map((role, index) => {
                        return (
                          <>
                            <InputText
                              key={index}
                              className="col-sm-12 mb-3"
                              value={role.Role}
                              readOnly
                            />
                          </>
                        );
                      })}
                  </Col>
                  <Col>
                    <h5>Owner</h5>
                    {roleOwnerList &&
                      roleOwnerList?.RolesList?.map((dropDownList, index) => {
                        const defaultOwner = dropDownList.DefaultOwner;

                        const placeholder = defaultOwner ? `${defaultOwner}` : "Select Owner"; // Adjusting placeholder based on defaultOwner
                        return (
                          <div ref={dropdownRef}>
                            {/* <Dropdown
                              key={index}
                              value={owner[index]} // Update value to reflect the current value in the state
                              onChange={(e) => updateObject(e.value, index)} // Pass e.value as the selected value
                              options={dropDownList.OwnerList}
                              optionLabel="Person"
                              placeholder={placeholder}
                              className="col-sm-12 mb-3"
                            /> */}
                            <CommonAutocomplete
                              width={width}
                              suggestions={dropDownList.OwnerList}
                              value={owner[index]}
                              onChange={(e) => {
                                updateObject(e.value, index, defaultOwner);
                              }}
                              placeholder={placeholder}
                              field="Person"
                              className="col-sm-12 mb-3"
                              setDropDownMatch={setDropDownMatch}
                            />
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col>
                  <div className="float-end ">
                    <div className="check-message">
                      <Checkbox
                        onChange={(e) => {
                          setUpdateEnable(true);
                          setOwnerDetails((prevState) => ({
                            ...prevState,
                            isDefaultOwnersSelected: e.checked,
                          }))
                        }}
                        checked={ownerDetails.isDefaultOwnersSelected}
                        name="confirmation"
                        value="Keep the setup for all future assignment"
                      />
                      <label htmlFor="remember" className=" ml-2">
                        Keep the setup for all future assignments in this project
                      </label>
                    </div>
                    <div className="massUpdateFooter">
                      <Button
                        type="btn"
                        onClick={(e) => { e.preventDefault(); setVisible(false); setUpdateEnable(false); }}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="btn"
                        className="btn btn-primary"
                        disabled={shouldDisableUpdate() || !dropDownMatch || !formDirty}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </form>
        </LoadingOverlay>
      </Dialog>
    </>
  );
}

export default MassupdateDialog;
