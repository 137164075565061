import { Button } from "react-bootstrap";
import {
  FooterContent,
  Confirmupdate,
  Cancel,
  Confirm,
  changeUpdateTooltip,
  changeUpdatePOATooltip,
} from "./ChangeNotificationConstant";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";

const ChangeNotificationFooter = ({
  handleCancel,
  editableFlag,
  PIMaterialNumber,
  copyFlag,
  handleConfirm,
  cicFlag,
  faFlag,
  POARequestType,
  POAStatus,
  isUserAccess,
}) => {
  const [checkbox, setCheckBox] = useState(false);
  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };

  const accessSecurityMatrix = isUserAccess; //usePriorityNew(location.pathname.split("/")[1]);

  return (
    <div className="form-buttons changefooter bottom-fixed text-left">
      <div className="margin-left-10">
        <input
          type="checkbox"
          onChange={(e) => handleCheckBox(e)}
          disabled={
            (copyFlag
              ? !accessSecurityMatrix || !editableFlag 
              : (!cicFlag && !faFlag) || !accessSecurityMatrix ) || POAStatus === "Awaiting Response" 
          }
        />
      </div>

      <div className="">{FooterContent(PIMaterialNumber)}</div>

      <Button className="button-layout" variant="secondary" onClick={() => handleCancel()}>
        {Cancel}
      </Button>
      <span className="confirmbutton">
        {copyFlag ? (
          <Button
            className="button-layout confirm_update"
            variant="primary"
            disabled={!editableFlag || !checkbox || !accessSecurityMatrix}
            onClick={() => handleConfirm()}
          >
            {!editableFlag && (
              <Tooltip
                position="top"
                target=".confirmbutton"
                className="tooltip-white-footer"
                content={
                  POARequestType === "Update Request" ? changeUpdatePOATooltip : changeUpdateTooltip
                }
              />
            )}
            {Confirmupdate}
          </Button>
        ) : (
          <Button
            className="button-layout"
            variant="primary"
            disabled={(!cicFlag && !faFlag) || !checkbox || !accessSecurityMatrix}
            onClick={() => handleConfirm()}
          >
            {Confirm}
          </Button>
        )}
      </span>
    </div>
  );
};
export default ChangeNotificationFooter;
