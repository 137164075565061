import { Route } from "react-router-dom";
import { uniqueId } from "lodash";

import AllTasksPage from "../AllTaksPage";
import DMDsbpTabPage from "../DMDsbpTabPage";
import DsbpPage from "../DsbpPage";
import DsbpTabPage from "../DsbpTabPage";
import MyTasksPage from "../MyTaskPage";
import ACP from "../components/ACP";
import ACIC from "../components/AWMJobs/ACIC";
import ACICMATRIX from "../components/AWMJobs/ACICMATRIX";
import APRA from "../components/AWMJobs/APRA";
import ARDT from "../components/AWMJobs/ARDT";
import CCD from "../components/AWMJobs/CCD";
import CNIQ from "../components/AWMJobs/CNIQ";
import CPPFA from "../components/AWMJobs/CPPFA";
import CPT from "../components/AWMJobs/CPT";
import DCD from "../components/AWMJobs/DCD";
import DDI from "../components/AWMJobs/DDI";
import DDT from "../components/AWMJobs/DDT";
import DNIQ from "../components/AWMJobs/DNIQ";
import DNPF from "../components/AWMJobs/DNPF";
import DPRA from "../components/AWMJobs/DPRA";
import UADI from "../components/AWMJobs/UADI";
import UBD from "../components/AWMJobs/UBD";
import UCIC from "../components/AWMJobs/UCIC";
import UCICMATRIX from "../components/AWMJobs/UCICMATRIX";
import UPRA from "../components/AWMJobs/UPRA";
import URDT from "../components/AWMJobs/URDT";
import ChangeNotification from "../components/ChangeNotification";
import AllProjects from "../components/Projects/AllProjects";
import MyProjects from "../components/Projects/MyProjects";
import UnAuthorizedComponent from "../components/UnAuthorized/UnAuthorizedComponent";
import LogOut from "../auth/LogOut";
import ProjectCreation from "../projectCreation";
import CallbackRoute from "./CallbackRoute";
import ErrorPage from "../errors/ErrorPage";
import Rework from "../components/containers/Rework/Rework";
import ReworkInput from "../components/ReworkPage/ReworkInput";
import Login from "../auth/Login";

export const publicRoutes = [
  { path: "/unauthorised", element: <UnAuthorizedComponent /> },
  { path: "/callback", element: <CallbackRoute /> },
  { path: "/error", element: <ErrorPage /> },
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <LogOut /> },
];

export const renderProtectedRoutes = () => (
  <>
    <Route path="/myProjects" element={<MyProjects />} />
    <Route path="/allProjects" element={<AllProjects />} />
    <Route path="/myTasks" element={<MyTasksPage />} />
    <Route path="/AllTasks" element={<AllTasksPage />} />

    {/* projectPlan */}
    <Route path="/projectPlan" element={<ProjectCreation />} />
    <Route
      path="/myProjects/projectPlan/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route path="/:page1/:page2/:ProjectID/rework" element={<Rework />}></Route>
    <Route path="/:page1/:page2/:ProjectID/rework" element={<Rework />}></Route>

    <Route
      path="/myProjects/projectPlan/:ProjectID/rework/task/:id"
      element={<ReworkInput />}
    ></Route>
    <Route
      path="/allProjects/projectPlan/:ProjectID/rework/task/:id"
      element={<ReworkInput />}
    ></Route>

    <Route path="/allTasks/rework/:id/:ProjectID" element={<ReworkInput />}></Route>
    <Route path="/myTasks/rework/:id/:ProjectID" element={<ReworkInput />}></Route>

    <Route
      path="/allProjects/projectPlan/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route
      path="/myTasks/projectPlan/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/projectPlan/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    {/* projectPlan */}
    {/* projectSetup */}
    <Route path="/projectSetup" element={<ProjectCreation key="projectSetup" />} />
    <Route
      path="/myProjects/projectSetup/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/allProjects/projectSetup/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/myTasks/projectSetup/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/projectSetup/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    {/* projectSetup */}
    {/* artworkAlignment */}
    <Route path="/:page1/:page2/UCICMATRIX/:TaskID/:ProjectID" element={<UCICMATRIX />} />
    <Route path="/:pageType/UCICMATRIX/:TaskID/:ProjectID" element={<UCICMATRIX />} />

    <Route path="/artworkAlignment" element={<ProjectCreation key="artworkAlignment" />} />
    <Route
      path="/myProjects/artworkAlignment/:assignee/:ProjectID"
      element={<ProjectCreation key={`artworkAlignment-${uniqueId()}`} />}
    />
    <Route
      path="/allProjects/artworkAlignment/:assignee/:ProjectID"
      element={<ProjectCreation key={`artworkAlignment-${uniqueId()}`} />}
    />
    <Route path="/:page1/:page2/UCIC/:TaskID/:ProjectID" element={<UCIC />} />
    <Route path="/:pageType/UCIC/:TaskID/:ProjectID" element={<UCIC />} />
    <Route
      path="/myTasks/artworkAlignment/:assignee/:ProjectID"
      element={<ProjectCreation key={`artworkAlignment-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/artworkAlignment/:assignee/:ProjectID"
      element={<ProjectCreation key={`artworkAlignment-${uniqueId()}`} />}
    />

    {/* mapping */}
    <Route path="/dependencyMapping" element={<ProjectCreation key="mapping" />} />
    <Route
      path="/myProjects/dependencyMapping/:ProjectID"
      element={<ProjectCreation key={`dependencyMapping-${uniqueId()}`} />}
    />
    <Route
      path="/allProjects/dependencyMapping/:ProjectID"
      element={<ProjectCreation key={`dependencyMapping-${uniqueId()}`} />}
    />
    <Route
      path="/myTasks/dependencyMapping/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/dependencyMapping/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    {/* mapping */}
    {/* readinessPerPMP */}
    <Route path="/artworkStatus" element={<ProjectCreation key="artworkStatus" />} />
    <Route
      path="/myProjects/artworkStatus/:ProjectID"
      element={<ProjectCreation key={`artworkStatus-${uniqueId()}`} />}
    />
    <Route
      path="/allProjects/artworkStatus/:ProjectID"
      element={<ProjectCreation key={`artworkStatus-${uniqueId()}`} />}
    />
    <Route
      path="/myTasks/artworkStatus/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/artworkStatus/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    <Route
      path="/capacityManagement/artworkStatus/:ProjectID"
      element={<ProjectCreation key={`projectSetup-${uniqueId()}`} />}
    />
    {/* readinessPerPMP */}

    <Route path="/:page1/:page2/DDI/:TaskID/:ProjectID" element={<DDI />} />
    <Route path="/:pageType/DDI/:TaskID/:ProjectID" element={<DDI />} />
    <Route path="/:page1/:page2/DDI/:TaskID/:ProjectID" element={<DDI />} />
    <Route path="/:pageType/DDI/:TaskID/:ProjectID" element={<DDI />} />
    <Route path="/:page1/:page2/UADI/:TaskID/:ProjectID" element={<UADI />} />
    <Route path="/:pageType/UADI/:TaskID/:ProjectID" element={<UADI />} />
    <Route path="/:page1/:page2/DDT/:TaskID/:ProjectID" element={<DDT />} />
    <Route path="/:pageType/DDT/:TaskID/:ProjectID" element={<DDT />} />

    <Route path="/:page1/:page2/ARDT/:TaskID/:ProjectID" element={<ARDT />} />
    <Route path="/:pageType/ARDT/:TaskID/:ProjectID" element={<ARDT />} />

    <Route path="/:page1/:page2/ACIC/:TaskID/:ProjectID" element={<ACIC />} />
    <Route path="/:pageType/ACIC/:TaskID/:ProjectID" element={<ACIC />} />

    <Route path="/:page1/:page2/RWK/:TaskID/:ProjectID" element={<ReworkInput />} />
    <Route path="/:pageType/RWK/:TaskID/:ProjectID" element={<ReworkInput />} />

    <Route path="/:page1/:page2/ACICMATRIX/:TaskID/:ProjectID" element={<ACICMATRIX />} />
    <Route path="/:pageType/ACICMATRIX/:TaskID/:ProjectID" element={<ACICMATRIX />} />

    <Route path="/:page1/:page2/DPRA/:TaskID/:ProjectID" element={<DPRA />} />
    <Route path="/:pageType/DPRA/:TaskID/:ProjectID" element={<DPRA />} />

    <Route path="/:page1/:page2/APRA/:TaskID/:ProjectID" element={<APRA />} />
    <Route path="/:pageType/APRA/:TaskID/:ProjectID" element={<APRA />} />

    <Route path="/:page1/:page2/URDT/:TaskID/:ProjectID" element={<URDT />} />
    <Route path="/:pageType/URDT/:TaskID/:ProjectID" element={<URDT />} />

    <Route path="/:page1/:page2/UPRA/:TaskID/:ProjectID" element={<UPRA />} />
    <Route path="/:pageType/UPRA/:TaskID/:ProjectID" element={<UPRA />} />
    <Route path="/:page1/:page2/CCD/:TaskID/:ProjectID" element={<CCD />} />
    <Route path="/:pageType/CCD/:TaskID/:ProjectID" element={<CCD />} />

    <Route path="/:page1/:page2/DCD/:TaskID/:ProjectID" element={<DCD />} />
    <Route path="/:pageType/DCD/:TaskID/:ProjectID" element={<DCD />} />
    <Route path="/:page1/:page2/CNIQ/:TaskID/:ProjectID" element={<CNIQ />} />
    <Route path="/:pageType/CNIQ/:TaskID/:ProjectID" element={<CNIQ />} />

    <Route path="/:page1/:page2/DNIQ/:TaskID/:ProjectID" element={<DNIQ />} />
    <Route path="/:pageType/DNIQ/:TaskID/:ProjectID" element={<DNIQ />} />
    <Route path="/allTasks/CPPFA/:TaskID/:ProjectID" element={<CPPFA />} />
    <Route path="/:page1/:page2/CPT/:TaskID/:ProjectID" element={<CPT />} />
    <Route path="/:pageType/CPT/:TaskID/:ProjectID" element={<CPT />} />
    <Route path="/:page1/:page2/DNPF/:TaskID/:ProjectID" element={<DNPF />} />
    <Route path="/:pageType/DNPF/:TaskID/:ProjectID" element={<DNPF />} />

    <Route path="/:page1/:page2/DCD/:TaskID/:ProjectID" element={<DCD />} />
    <Route path="/:pageType/DCD/:TaskID/:ProjectID" element={<DCD />} />
    <Route path="/DSBP/:ProjectID" element={<DsbpPage />} />
    <Route path="/:page1/:page2/UBD/:TaskID/:ProjectID" element={<UBD />} />
    <Route path="/:pageType/UBD/:TaskID/:ProjectID" element={<UBD />} />

    <Route path="/myTasks/DSBP/tab/artworkAlignment/:ProjectID" element={<DsbpTabPage />} />
    <Route path="/allTasks/DSBP/tab/artworkAlignment/:ProjectID" element={<DsbpTabPage />} />
    <Route path="/myProjects/DSBP/tab/artworkAlignment/:ProjectID" element={<DsbpTabPage />} />
    <Route path="/allProjects/DSBP/tab/artworkAlignment/:ProjectID" element={<DsbpTabPage />} />

    <Route
      path="/myTasks/DSBP/tab/dependencyMapping/:assignee/:ProjectID"
      element={<DMDsbpTabPage />}
    />
    <Route
      path="/allTasks/DSBP/tab/dependencyMapping/:assignee/:ProjectID"
      element={<DMDsbpTabPage />}
    />
    <Route
      path="/myProjects/DSBP/tab/dependencyMapping/:assignee/:ProjectID"
      element={<DMDsbpTabPage />}
    />
    <Route
      path="/allProjects/DSBP/tab/dependencyMapping/:assignee/:ProjectID"
      element={<DMDsbpTabPage />}
    />
    <Route path={`/capacityManagement`} element={<ACP />}>
      <Route path={`dashboard`} element={<ACP />} />
      <Route path={`planning`} element={<ACP />} />
      <Route path={`booking`} element={<ACP />} />
      <Route path={`bveLoop`} element={<ACP />} />
      <Route path={`reports`} element={<ACP />} />
    </Route>
    {/* <Route path="/goJs" element={<GoJsComponent />} /> */}
    <Route path="/unauthorised" element={<UnAuthorizedComponent />} />
    {/* Artwork Change Notification routes */}
    {/* myproject */}
    <Route
      path="/myProjects/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myProjects/DSBP/tab/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myProjects/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myProjects/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myProjects/DSBP/tab/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myProjects/artworkStatus/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    {/* allprojects */}
    <Route
      path="/allProjects/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allProjects/DSBP/tab/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allProjects/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allProjects/DSBP/tab/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allProjects/artworkStatus/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    {/* mytasks */}
    <Route
      path="/myTasks/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myTasks/DSBP/tab/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myTasks/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myTasks/DSBP/tab/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/myTasks/artworkStatus/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    {/* alltasks */}
    <Route
      path="/allTasks/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allTasks/DSBP/tab/artworkAlignment/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allTasks/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allTasks/DSBP/tab/dependencyMapping/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />
    <Route
      path="/allTasks/artworkStatus/ChangeNotification/:ProjectID"
      element={<ChangeNotification />}
    />

    {/* files and folder*/}

    <Route path="/files" element={<ProjectCreation />} />
    <Route
      path="/myProjects/files/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route path="/myProjects/files/:ProjectID/rework" element={<Rework />}></Route>

    <Route
      path="/allProjects/files/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route
      path="/myTasks/files/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    <Route
      path="/allTasks/files/:ProjectID"
      element={<ProjectCreation key={`projectPlan-${uniqueId()}`} />}
    />
    {/* files and folder */}
  </>
);
