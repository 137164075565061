import _ from "lodash";
import { Tag } from "primereact/tag";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { AcpService } from "../../../service/ACPService";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProjectService } from "../../../service/PegaService";
import ProjectListHeader from "../MyProjects/ProjectListHeader";
import ProjectNameHeader from "../MyProjects/ProjectNameHeader";
import CustomizeView from "../../common/CustomizeView";
import {
  generateUniqueKey,
  multiFilterChange,
  getModifiedProjectHeaderNames,
  checkIfOrderSame,
} from "../../../utils";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { bufferToWork, onSortData } from "../../../utils";
import Footer from "../../common/tableFooter/tableFooter";
import { AcpCommonService } from "../../../service/AcpCommonService";
import ACPFilter from "../../ACP/common/ACPFilter";

const AllProjectList = (props) => {
  const op = useRef(null);
  const dt = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filterArray = useRef([]);
  const colAllSelected = useRef([]);
  const currentUrl = location.pathname;
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  // setSavedColumnWidth(saveColumnWidth);
  const [filters, setFilters] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pegadata, setPegaData] = useState(null);
  const [isSearch, isSearchSet] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  // const [xCoordinate, setXCoordinate] = useState(0);
  // const [yCoordinate, setYCoordinate] = useState(0);
  const [tableRender, setTableRender] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const allProjectList = useSelector((state) => state.myProject);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [customizeViewFields, setCustomizeViewFields] = useState({});
  const [updatedAllColumnNames, setUpdatedAllColumnNames] = useState([]);
  const [initialColumnLenght, setinitialColumnLenght] = useState({});
  const [customViewIsAddedMyProject, isCustomViewIsAddedMyProject] = useState(false);
  const [ProjectData, setProjectData] = useState(0);
  const [resizeMode, setResizeMode] = useState("expand");
  const StorageData = localStorage.getItem("customizeViewFieldsAllProjects");
  const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
  const { loading } = allProjectList;

  const searchHeader = projectColumnName.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }),
    {}
  );

  const allColumnNamesConstValue = [
    "Project_ID",
    "Project_Name",
    "Artwork_Category",
    "Artwork_Brand",
    "Artwork_SMO",
    "Project_State",
    "Buffer_To_Work",
    "Estimated_AW_Printer",
    "PM",
    "Artwork_Status",
  ];

  useEffect(() => {
    if (projectColumnName.length < 6) {
      setResizeMode("fit");
    } else {
      setResizeMode("expand");
    }
  }, [projectColumnName]);

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };
  const [conditionMatched, setConditionMatched] = useState(false);

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : pegadata;
    const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(sortedData, column, direction);

    setSortData([column, direction]);
    localStorage.setItem("allProjectSortingData", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    }
    // else {
    //   setSelectedProdSrchList(sortedDataSet);
    // }
  };

  useEffect(() => {
    const ProjectData = _.cloneDeep(allProjectList.allProjects);
    setProjectData(ProjectData?.length);
    let allCol = [];
    if (ProjectData?.length) {
      allCol = Object.keys(ProjectData[0]);
      allCol.push("Artwork_Status");
    }
    let columnWidthMyProject = {};
    if (allCol.length) {
      allCol.forEach((column) => {
        columnWidthMyProject[column] = 100;
      });
    }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthAllProject");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthAllProject", JSON.stringify(columnWidthMyProject));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetAllProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }
    let availableFields;
    const selectedFeilds = ProjectService.getAllColumnNamesAllProjects();
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !selectedFeilds.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    setinitialColumnLenght({
      availableFields: availableFields.length,
      freezedColumns: 0,
      selectedFields: selectedFeilds.length,
    });
  }, []);

  useEffect(() => {
    getCustomizedView();
  }, [allColumnNames]);

  const reorderColumns = (columns) => {
    const requiredColumnOrderArray = [
      "Project_ID",
      "Project_Name",
      "Initiative_Group_Name",
      "Project_Description",
      "BU",
      "Artwork_Category",
      "Artwork_Brand",
      "Project_region",
      "Artwork_SMO",
      "Cluster",
      "Project_Scale",
      "Project_State",
      "Buffer_To_Work",
      "Estimated_No_Of_DI",
      "Estimated_No_Of_DT",
      "Estimated_No_Of_NPF",
      "Estimated_No_Of_IQ",
      "Estimated_No_Of_PRA",
      "Estimated_No_Of_CICs",
      "Estimated_No_Of_POAs",
      "Estimated_SOS",
      "Estimated_SOP",
      "Estimated_AW_Printer",
      "Estimated_AW_Readiness",
      "IL",
      "PM",
      "Comments",
      "Project_Type",
      "Production_Strategy",
      "Tier",
      "Company",
      "Artwork_Status",
    ];

    let reorderedColumns = [];
    requiredColumnOrderArray.forEach((rcl) => {
      columns.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  };

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        // const ProjectData = await ProjectService.getProjectData();
        const ProjectData = _.cloneDeep(allProjectList.allProjects);

        if (ProjectData && ProjectData.length) {
          ProjectData.filter((field) => {
            if (field.Artwork_Category) {
              let categoryNames = field?.Artwork_Category?.map((item) => item.Category_Name).join(
                ", "
              );
              field.Artwork_Category = categoryNames;
            }

            if (field.Artwork_SMO) {
              let SMOName = field?.Artwork_SMO?.map((item) => item.SMO_Name).join(", ");
              field.Artwork_SMO = SMOName;
            }

            if (field.Artwork_Brand) {
              let brandName = field?.Artwork_Brand?.map((item) => item.Brand_Name).join(", ");
              field.Artwork_Brand = brandName;
            }

            return field;
          });
        }

        if (ProjectData?.length) {
          let allCol = Object.keys(ProjectData[0]);
          allCol.push("Artwork_Status");
          setAllColumnNames(reorderColumns(allCol));
        }
        // const columnNames = await ProjectService.getAllColumnNames();
        // localStorage.setItem("allColumnNames", JSON.stringify(columnNames));

        // const columnNamesAllProjects =
        //   await ProjectService.getAllColumnNamesAllProjects();
        // localStorage.setItem(
        //   "allColumnNamesAllProjects",
        //   JSON.stringify(columnNamesAllProjects)
        // );

        let filteredPegaDataJson = localStorage.getItem("allProjectColumnWiseFilterData");
        const filteredPegaData = JSON.parse(filteredPegaDataJson);

        if (filteredPegaData && filteredPegaData.length) {
          setFilters(filteredPegaData);
          setSelectedFields(filteredPegaData);
          setPegaData(ProjectData);
        } else setPegaData(ProjectData);
        if (!initialPegaData) {
          setInitialPegaData(ProjectData);
        }

        // according to pathname we need to call api and store column name in local storage

        let columnNamesJson = localStorage.getItem("allColumnNamesAllProjects");
        let columnNames = JSON.parse(columnNamesJson);

        if (columnNames && columnNames?.length) {
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        } else {
          let columnNames = await ProjectService.getAllColumnNamesAllProjects();
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setUpdatedAllColumnNames(columnNames);
          setProjectColumnNames(columnNames);
          localStorage.setItem("allColumnNamesAllProjects", JSON.stringify(columnNames));
        }

        // const jsonSortingData = await ProjectService.getSortingData();
        //   localStorage.setItem('sortingData', JSON.stringify(jsonSortingData));
        // const jsonFilterData = await ProjectService.getFrozenData();
        // localStorage.setItem('frozenData', JSON.stringify(jsonFilterData))

        // get sort data from local storage and add in state
        let jsonSortingData1 = localStorage.getItem("allProjectSortingData");
        const sortingData = JSON.parse(jsonSortingData1);

        if (sortingData && sortingData.length) {
          const sortedData = onSortData(sortingData[0], sortingData[1], ProjectData);
          setPegaData(sortedData);
          setSortData([sortingData[0], sortingData[1]]);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
    if (allProjectList?.allProjects?.length !== 0) {
      setLoader(false);
    }
  }, [allProjectList?.allProjects]);

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
    } else {
      let columnIndex = frozenCoulmns?.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
    }
    setFrozenColumn(frozenCoulmns);
    const arr = getCustomizedView(true);
    setCustomizeViewFields(arr);
    localStorage.setItem("customizeViewFieldsAllProjects", JSON.stringify(arr));
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    let projectId = options["Project_ID"];
    return (
      <>
        {field === "Project State" && (
          <Tag
            value=""
            style={{
              backgroundColor: "#DFEBFF",
              color: "#003DA5",
              border: "1px solid",
            }}
          >
            Active
          </Tag>
        )}
        {field === "Artwork_Status" && (
          <Tag
            value="view"
            style={{
              backgroundColor: "white",
              color: "gray",
              border: "1px solid",
              padding: "0.15rem 0.4rem",
              margin: "2px 0",
              cursor: "pointer",
            }}
            onClick={() => navigate(`${currentUrl}/artworkStatus/${projectId}`)}
          ></Tag>
        )}

        {field === "Project_Name" && (
          <span
            title={options[field]}
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                dispatch(SpecialProjectAction(options?.SpecialProject));
                if (options?.SpecialProject === "Yes") {
                  navigate(`${currentUrl}/artworkStatus/${projectId}`);
                } else {
                  if (options.Action === "Draft") {
                    navigate(`${currentUrl}/projectSetup/${projectId}`);
                  } else {
                    navigate(`${currentUrl}/projectPlan/${projectId}`);
                  }
                }
              }
            }}
          >
            {options[field]}
          </span>
        )}

        {field === "Project_ID" && (
          <span
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                dispatch(SpecialProjectAction(options?.SpecialProject));
                if (options?.SpecialProject === "Yes") {
                  navigate(`${currentUrl}/artworkStatus/${projectId}`);
                } else {
                  if (options.Action === "Draft") {
                    navigate(`${currentUrl}/projectSetup/${projectId}`);
                  } else {
                    navigate(`${currentUrl}/projectPlan/${projectId}`);
                  }
                }
              }
            }}
          >
            {" "}
            {options[field]}{" "}
          </span>
        )}
        {field === "Estimated_SOP" && <span title={options[field]}>{options[field]}</span>}
        {field === "Estimated_AW_Printer" && <span title={options[field]}>{options[field]}</span>}
        {field === "Estimated_AW_Readiness" && <span title={options[field]}>{options[field]}</span>}
        {field === "Estimated_SOS" && <span title={options[field]}>{options[field]}</span>}
        {field === "Buffer_To_Work" && options[field] !== "" && bufferToWork(options[field])}
        {field !== "Artwork_Status" &&
          field !== "Estimated_SOP" &&
          field !== "Estimated_SOS" &&
          field !== "Estimated_AW_Readiness" &&
          field !== "Estimated_AW_Printer" &&
          field !== "Project_Name" &&
          field !== "Project_ID" &&
          field !== "Buffer_To_Work" && (
            <>
              <span title={options[field]}>{options[field]}</span>
            </>
          )}
      </>
    );
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const dynamicColumns = () => {
    if (projectColumnName.length) {
      return projectColumnName.map((ele, i) => {
        return (
          <Column
            filter
            key={ele}
            field={ele}
            filterField={ele}
            alignFrozen="left"
            columnKey={ele || i}
            showFilterMenu={false}
            filterPlaceholder={ele}
            body={fullKitReadinessBody}
            frozen={frozenCoulmns?.includes(ele)}
            className={frozenCoulmns?.includes(ele) ? "frozen-width" : ""}
            header={ProjectNameHeader(
              ele,
              frozenCoulmns?.length && frozenCoulmns?.includes(ele),
              sortData,
              projectNameOnClick,
              selectedFields
            )}
          />
        );
      });
    }
  };

  const clearFilters = () => {
    const defaultCol = [
      "Project_ID",
      "Project_Name",
      "Artwork_Category",
      "Artwork_Brand",
      "Artwork_SMO",
      "Project_State",
      "Buffer_To_Work",
      "Estimated_AW_Printer",
      "PM",
      "Artwork_Status",
    ];
    
    localStorage.setItem("allProjectColumnWiseFilterData", JSON.stringify({}));
    // localStorage.setItem("allProjectSortingData", JSON.stringify({}));
    // localStorage.setItem("allProjectFrozenData", JSON.stringify({}));
    localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    // localStorage.removeItem("isColWidthSetAllProject");
    setPegaData(initialPegaData);
    setProjectColumnNames(defaultCol);
    // setReorderedColumn(false);
    setSelectedFields({});
    setFrozenColumn([]);
    // setTableRender(true);
    // setColWidth(false);
    isSearchSet(false);
    setVisible(false);
    // setSortData([]);
    setFilters([]);
    setToInitialCustomizeView();
    setResizeMode("expand");
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef =
      selectedFieldsRef?.current.length > 0 &&
      selectedFieldsRef?.current.map((el, i) => {
        if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
          return {
            ...el,
            [`${selectedColumnName}`]: arr[selectedColumnName],
          };
        } else {
          return el;
        }
      });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentAllProjects");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const filterData = AcpService.filterDataByfilters(
          pegadata,
          _selectedFieldsRefWithoutEmptyArray,
          setConditionMatched,
          props.header
        );
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  const onColumnResizeEnd = (event) => {
    // console.log("updated column name", event.column, event?.element?.clientWidth);
    // console.log("width", event.element.offsetWidth, event.column);

    let columnWidthMyProject = {};
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthAllProject");
    if (jsonColumnWidthMyProject) {
      columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
    }
    // const updatedColumns = [...projectColumnName];
    // const resizedColumn = updatedColumns.find(
    //   (col) => col === event.column.props.field
    // );
    localStorage.setItem("columnWidthAllProject", JSON.stringify(columnWidthMyProject));
    columnWidthMyProject[event.column.props.field] = event.element.offsetWidth;
    localStorage.setItem("isColWidthSetAllProject", JSON.stringify(true));
    setProjectColumnNames(projectColumnName);
    setTableRender(false);
    setColWidth(true);
    setVisible(false);
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  // in header save setting button was there and due to requirement I removed that
  //code is kept here if requirement comes
  const saveSettings = () => {
    localStorage.setItem("allProjectSortingData", JSON.stringify(sortData));
    localStorage.setItem("allProjectFrozenData", JSON.stringify(frozenCoulmns));
    localStorage.setItem("allProjectColumnWiseFilterData", JSON.stringify(filters));
    localStorage.setItem("allColumnNamesAllProjects", JSON.stringify(projectColumnName));
  };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      // only splice array when item is found
      projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
      setReorderedColumn(true);
    }
    localStorage.setItem("allColumnNamesAllProjects", JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
    setReorderedColumn(true);
    setTableRender(false);
  };

  const clearFilter = () => {
    localStorage.setItem("allProjectColumnWiseFilterData", JSON.stringify({}));
    localStorage.setItem("customizeViewFieldsAllProjects", JSON.stringify({}));
    setPegaData(initialPegaData);
    setSelectedFields({});
    isSearchSet(false);
    setFilters([]);
  };

  const clearColumnWiseFilter = () => {
    // let jsonFrozenItem = localStorage.getItem("allProjectFrozenData");
    // const frozenItem = JSON.parse(jsonFrozenItem);
    // if (frozenItem && frozenItem.length && frozenItem.includes(selectedColumnName)) {
    //   const index = frozenItem.indexOf(selectedColumnName);
    //   frozenItem.splice(index, 1);
    //   localStorage.setItem("allProjectFrozenData", JSON.stringify(frozenItem));
    //   setFrozenColumn(frozenItem);
    // }
    // if (frozenCoulmns.includes(selectedColumnName)) {
    //   const index = frozenCoulmns.indexOf(selectedColumnName);
    //   frozenCoulmns.splice(index, 1);
    //   setFrozenColumn(frozenCoulmns);
    //   setProjectFrozen(!ProjectFrozen);
    // }
    // let jsonSortItem = localStorage.getItem("allProjectFrozenData");
    // const sortItem = JSON.parse(jsonSortItem);
    // if (sortItem && sortItem.length && sortItem[0] === selectedColumnName) {
    //   localStorage.setItem("allProjectFrozenData", JSON.stringify([]));
    // }
    // if (sortData && sortData?.length && sortData[0] === selectedColumnName) {
    //   localStorage.removeItem("sortedColumnAllProjects");
    //   //setClearAllFilter(true);
    //   setSortData([]);
    // }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentAllProjects");
    }
    let data = [...(pegadata || [])];
    Object.keys(__selectedFields).forEach((el) => {
      if (__selectedFields[el]?.length) {
        data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
      }
    });
    if (data?.length) {
      setFilters(data);
    } else {
      setFilters([]);
    }
  };

  const getUpdatedAllColumnFormat = (column) => {
    const finalData = column.map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    const data = getModifiedProjectHeaderNames(finalData);
    return data;
  };

  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "freezed Columns",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated;
  };

  const setToInitialCustomizeView = () => {
    let availableFields;
    const selectedFeildsVal = allColumnNamesConstValue;
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !allColumnNamesConstValue?.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    const customizeViewFieldsUpdated = formCustomizeObject(availableFields, [], selectedFeildsVal);
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem(
      "customizeViewFieldsAllProjects",
      JSON.stringify(customizeViewFieldsUpdated)
    );
  };

  const getCustomizedView = (isFrozen) => {
    const selectedData = customizeViewFieldsStorage?.selectedFields?.fieldsData;
    const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData;
    const availableData = customizeViewFieldsStorage?.availableFields?.fieldsData;
    let availableFields;
    if (availableData?.length) {
      availableFields = availableData;
    } else {
      let updatedColumnFinal = allColumnNames?.filter(function (objFromA) {
        return !projectColumnName.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });
      const intermidaiteColumn = AcpService?.removeCommonValues(
        updatedColumnFinal,
        freezedData?.length ? freezedData : frozenCoulmns
      );
      const availableColumnToSelect = AcpService?.removeCommonValues(
        intermidaiteColumn,
        selectedData?.length ? selectedData : projectColumnName
      );
      availableFields = availableColumnToSelect?.length
        ? availableColumnToSelect
        : updatedColumnFinal;
    }

    const freezedColumns = isFrozen
      ? frozenCoulmns
      : freezedData?.length
      ? freezedData
      : frozenCoulmns;

    let selectedFields = selectedData?.length ? selectedData : projectColumnName;
    selectedFields = AcpService?.removeCommonValues(selectedFields, freezedColumns);

    const customizeViewFieldsUpdated = formCustomizeObject(
      availableFields,
      freezedColumns,
      selectedFields
    );

    const column = AcpService?.removeCommonValues(
      selectedFields,
      freezedData?.length ? freezedData : frozenCoulmns
    );
    const projectColumnNamesUpdated =
      freezedColumns.length > 0 ? [...freezedColumns, ...column] : selectedFields;
    setProjectColumnNames(projectColumnNamesUpdated);
    if (!isFrozen) {
      setFrozenColumn(freezedColumns);
      setCustomizeViewFields(customizeViewFieldsUpdated);
    }
    return customizeViewFieldsUpdated;
  };

  const handleCustomizedView = (data) => {
    if (data !== undefined && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      let selectedData = parsedData?.selectedFields?.fieldsData;
      localStorage.setItem("customizeViewFieldsAllProjects", JSON.stringify(parsedData));
      selectedData = AcpService?.removeCommonValues(selectedData, freezedData);
      selectedData = [...freezedData, ...selectedData];
      if (!checkIfOrderSame(ProjectService.getAllColumnNamesAllProjects(), selectedData)) {
        isCustomViewIsAddedMyProject(true);
      } else {
        isCustomViewIsAddedMyProject(false);
      } //enable reset button if selected feilds order has cahnged
      setProjectColumnNames(selectedData);
      setCustomizeViewFields(parsedData);
      freezedData?.length > 0 && setFrozenColumn([...freezedData]);
      freezedData?.length === 0 && setFrozenColumn([]); //when you drag from frozen to selected reset the state
    }
  };
  const checkIsFilterApplied = () => {
    let isSelectedVal = false;
    if (selectedFields && Object.keys(selectedFields)?.length > 0) {
      isSelectedVal = Object.values(selectedFields)?.some((value) => value.length > 0);
    }
    return isSelectedVal;
  };

  const isFilterEnabled = isSearch  || checkIsFilterApplied();

  const isResetEnabled = isFilterEnabled || customViewIsAddedMyProject || frozenCoulmns?.length > 0;

  return (
    <>
      <div className="projectTable myProjectAnddAllProjectList ">
        <LoadingOverlay active={loading || loader || pegadata === null} spinner text="">
          <>
            {pegadata !== undefined && (
              <ProjectListHeader
                allData={pegadata}
                filterFLag={false}
                header={props.header}
                exportCSV={exportCSV}
                setVisible={setVisible}
                isTreeTableFlag={false}
                headers={projectColumnName}
                CustomizeViewFlag={false}
                ResetToDefaultFlag={false}
                clearFilters={clearFilters}
                saveSettings={saveSettings}
                onSearchClick={onSearchClick}
                isResetEnabled={isResetEnabled}
                isFilterEnabled={isFilterEnabled}
                selected={filters}
                clearFiltersResetToDefault={clearFilters}
              />
            )}
            {/* <CustomisedView
            visible={visible}
            setVisible={setVisible}
            allColumnNames={allColumnNames}
            resetToPgDefault={resetToPgDefault}
            projectColumnName={projectColumnName}
            setProjectColumnNames={setProjectColumnNames}
            saveAsPersonaliDefault={saveAsPersonaliDefault}
          /> */}

            <CustomizeView
              availableFields={[]}
              showTaskDialog={visible}
              headerName={"All Projects"}
              onClose={() => setVisible(false)}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={handleCustomizedView}
              resetToPgDefault={clearFilters}
              allColumns={getUpdatedAllColumnFormat(allColumnNames)}
            />

            {/* <ProjectListFilter
              op={op}
              onSort={onSort}
              filters={filters}
              sortData={sortData}
              pageName="All Project"
              projectData={pegadata}
              setFilters={setFilters}
              setSortData={setSortData}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              setTableRender={setTableRender}
              // setXCoordinate={xCoordinate}
              // setYCoordinate={yCoordinate}
              selectedFields={selectedFields}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              selectedColumnName={selectedColumnName}
              setReorderedColumn={setReorderedColumn}
              onGlobalFilterChange={onGlobalFilterChange}
              setAlignFrozenColumn={setAlignFrozenColumn}
              clearColumnWiseFilter={clearColumnWiseFilter}
              setProjectColumnNames={setProjectColumnNames}
            /> */}
            <ACPFilter
              op={op}
              onSort={onSort}
              pageName={props.header}
              filters={filters}
              sortData={sortData}
              setFilters={setFilters}
              tableRender={tableRender}
              setSortData={setSortData}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              setTableRender={setTableRender}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              isReorderedColumn={isReorderedColumn}
              selectedColumnName={selectedColumnName}
              setReorderedColumn={setReorderedColumn}
              onGlobalFilterChange={onGlobalFilterChange}
              setProjectColumnNames={setProjectColumnNames}
              setSelectedColumnName={setSelectedColumnName}
              clearColumnWiseFilter={clearColumnWiseFilter}
              // acpBookingFrozenColumn={acpBookingFrozenColumn}
              projectData={pegadata}
            />

            <DataTable
              ref={dt}
              scrollable
              className="mt-3"
              resizableColumns
              reorderableColumns
              // loading={loading}
              dataKey="Project_ID"
              filters={searchHeader}
              columnResizeMode={resizeMode}
              responsiveLayout="scroll"
              // selectionMode="single"
              filterDisplay={isSearch && "row"}
              onColReorder={storeReorderedColumns}
              onColumnResizeEnd={onColumnResizeEnd}
              // key={generateUniqueKey("Project_ID")}
              onResize={(e) => console.log("resize", e)}
              value={filters.length ? filters : pegadata}
              onResizeCapture={(e) => console.log("e", e)}
              // onSelectionChange={(e) => {
              //   navigate(`/projectPlan/${e.value.ProjectID}`);
              // }}
              // key={tableRender ? `"Project_ID" + timestamp` : ""}
              key={tableRender ? generateUniqueKey("Project_ID") : ""}
              emptyMessage={loader || loading || pegadata === null ? " " : "No records found"}
            >
              {dynamicColumns(projectColumnName)}
            </DataTable>
          </>
        </LoadingOverlay>
      </div>
      {!loader && pegadata && (
        <Footer
          className="footer"
          totalObjects={filters?.length ? filters?.length : pegadata?.length}
          isSelected={false}
        />
      )}
    </>
  );
};
export default AllProjectList;
