import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AcpService } from "../../../service/ACPService";
import { fetchExternalCapacityData } from "../../../apis/acpApi";
import { getStartAndEndOfMonth, fillMissingDates } from "../../../service/DashboardService";

function ExternalCapacityTable({
  setTableEdited,
  enableEdit,
  selectedMonth,
  setEditedRows,
  supplierSelected,
  userGroup,
  setLoader,
  loadTable,
}) {
  const CalendarHeaders = ["Date", "Day"];
  const [calendarData, setCalendarData] = useState([]);
  const [taskData, setTaskData] = useState([]);

  useEffect(() => {
    setLoader(true);
    AcpService.getDaysInMonth(selectedMonth, setCalendarData);
    if (!AcpService.isUBN(supplierSelected) && !AcpService.isUBN(selectedMonth))
      getExternalCapacityData(userGroup, supplierSelected);
  }, [supplierSelected, selectedMonth, loadTable]);

  const getExternalCapacityData = async () => {
    const dates = getStartAndEndOfMonth(selectedMonth);
    const payload = {
      ManageExternalCapacity: {
        Start_Date: dates.startDate,
        End_Date: dates.endDate,
        Supplier: supplierSelected,
        UserGroup: userGroup,
      },
    };
    fetchExternalCapacityData(payload).then((data) => {
      setLoader(false);
      let month = selectedMonth?.getMonth() + 1;
      let year = selectedMonth?.getFullYear();
      let _data = data?.ManageCapacityExt;
      if (_data?.length) {
        const result = fillMissingDates(_data, month, year);
        setTaskData(result);
      } else {
        createEmptyEditableTable(month, year);
      }
    });
  };

  const createEmptyEditableTable = (month, year) => {
    let template = [
      {
        Capacity: 0,
        TaskType: "Upload CIC",
        Date: AcpService.convertToShortDateFormat(selectedMonth),
        SerialNumber: "",
      },
      {
        Capacity: 0,
        TaskType: "FA Assembly",
        Date: AcpService.convertToShortDateFormat(selectedMonth),
        SerialNumber: "",
      },
    ];
    let emptyCells = fillMissingDates(template, month, year);
    setTaskData(emptyCells);
  };
  useEffect(() => {
    let editedRows = [];
    taskData?.forEach((task, inx) => {
      if (task?.["Upload CIC_isEdited"]) {
        let local = {};
        local.BVE = supplierSelected;
        local["Capacity"] = task["Upload CIC"];
        local["TaskType"] = "Upload CIC";
        local["Date"] = AcpService.changeDateFromISTtoUTC(new Date(task.Date));
        local["SerialNumber"] = task["Upload CIC SerialNumber"];
        editedRows.push(local);
      }
      if (task?.["FA Assembly_isEdited"]) {
        let local = {};
        local.BVE = supplierSelected;
        local["Capacity"] = task["FA Assembly"];
        local["TaskType"] = "FA Assembly";
        local["Date"] = AcpService.changeDateFromISTtoUTC(new Date(task.Date));
        local["SerialNumber"] = task["FA Assembly SerialNumber"];
        editedRows.push(local);
      }
    });
    setEditedRows(editedRows);
  }, [taskData]);

  const CalendarTableBody = (rowData, options) => {
    return (
      <p
        className={
          calendarData[options.rowIndex]["DayAsNumber"] === 0 ||
          calendarData[options.rowIndex]["DayAsNumber"] === 6
            ? "calendar-weekend"
            : "calendar-weekday"
        }
      >
        {options.field === "Date"
          ? AcpService.convertToShortDateFormat(
              calendarData[options.rowIndex][options.field],
              "DD-MMM-YYYY"
            )
          : calendarData[options.rowIndex][options.field]}
      </p>
    );
  };
  const TaskTableBody = (rowData, options) => {
    return (
      <input
        key={options.rowIndex + "_key"}
        disabled={
          calendarData &&
          (calendarData[options.rowIndex]?.DayAsNumber === 0 ||
            calendarData[options.rowIndex]?.DayAsNumber === 6)
            ? true
            : enableEdit
        }
        className={options.isSelected ? "cap-table-input edited" : "cap-table-input"}
        contentEditable
        value={taskData[options?.rowIndex][options?.field]}
        onChange={(e) => {
          if (!/^[0-9]*$/.test(e.target.value)) return;
          let _taskData = [...taskData];
          _taskData[options?.rowIndex][options?.field] = Number(e.target.value);
          setTableEdited(true);
          setTaskData(_taskData);
          rowData[options?.field + "_isEdited"] = true;
        }}
      ></input>
    );
  };
  const generateColumns = (rowData, options) => {
    let headers = [];
    CalendarHeaders?.forEach((el, inx) => {
      headers.push(
        <Column
          key={inx}
          field={el}
          frozen
          body={CalendarTableBody}
          alignFrozen="top"
          header={el}
          className="tableSubHeader columnDataText"
        />
      );
    });
    return headers;
  };

  return (
    <div className="external-cap-main">
      <div>
        <DataTable
          scrollable
          value={calendarData}
          tableStyle={{
            width: "max-content",
            minWidth: "100%",
          }}
        >
          {generateColumns()}
        </DataTable>
      </div>
      <div>
        <DataTable
          scrollable
          value={taskData}
          tableStyle={{
            width: "max-content",
            minWidth: "100%",
          }}
          rowClassName={(rowData) =>
            rowData["FA Assembly_isEdited"] ? "highlight-row" : "normal-row"
          }
        >
          <Column
            field="FA Assembly"
            frozen
            body={TaskTableBody}
            alignFrozen="top"
            header="FA Assemble"
            className="tableSubHeader columnDataText"
          />
        </DataTable>
      </div>
      <div>
        {" "}
        <DataTable
          scrollable
          value={taskData}
          tableStyle={{
            width: "max-content",
            minWidth: "100%",
          }}
          rowClassName={(rowData) =>
            rowData["Upload CIC_isEdited"] ? "highlight-row" : "normal-row"
          }
        >
          <Column
            field="Upload CIC"
            frozen
            alignFrozen="top"
            body={TaskTableBody}
            header="Upload CIC"
            className="tableSubHeader columnDataText"
          />
        </DataTable>
      </div>
    </div>
  );
}

export default ExternalCapacityTable;
