import React from "react";
import { Button } from "react-bootstrap";
import { clearToken } from "../store/actions/AuthenticationActions";
import { store } from "../store/store";
import PgLogo from "../assets/images/logo.svg";
import "./index.scss";

export default function LogOut() {
  const handleLogin = () => {
    // redirect back to application url
    window.location.href = process.env.REACT_APP_URI;
  };

  return (
    <div className="logout-page">
      <div className="logout-text">
        <div id="pg-logo">
          <img src={PgLogo} alt="PG logo" />
          <p>Artwork Agility Suite</p>
        </div>
        <h4>You have successfully logged out!</h4>
        <p>
          Please click <b>Login</b> button to return to your account
        </p>
        <Button onClick={handleLogin} className="login-btn">
          Login Here!
        </Button>
      </div>
    </div>
  );
}
