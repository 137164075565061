// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-page {
  display: flex;
  justify-content: center;
  text-align: center;
}
.logout-page .logout-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.logout-page .logout-text #pg-logo {
  margin-bottom: 10px;
}
.logout-page .logout-text #pg-logo img {
  width: 40%;
  margin-bottom: 10px;
}
.logout-page .logout-text .login-btn {
  margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/auth/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AAFM;EACE,UAAA;EACA,mBAAA;AAIR;AADI;EACE,mBAAA;AAGN","sourcesContent":[".logout-page {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  .logout-text {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    height: 100vh;\n    #pg-logo {\n      margin-bottom: 10px;\n      img {\n        width: 40%;\n        margin-bottom: 10px;\n      }\n    }\n    .login-btn {\n      margin-bottom: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
