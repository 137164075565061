import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Form } from "react-bootstrap";
import { Column } from "primereact/column";
import filter from "../../assets/images/filter.svg";
import "../Projects/MyProjects/index.scss";
import DsbpCommonPopup from "./DsbpCommonPopup";
import DsbpRejectDialog from "./RejectDialog";
import DsbpActionDialog from "./DsbpActionDialog";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ArtWorkTabValuesAction } from "../../../src/store/actions/ArtWorkTabValuesActions";
import { getDropDownValues } from "../../apis/getDropDownValues";
import { AWM_Biocide, AWM_AISE, AWM_Sellable } from "../ACP/constant";
import AlertIcon from "../common/AlertIcon/AlertIcon";
import { dsbpConstant } from "./constant";
import { checkIfRedIcon, checkIfGreyIcon } from "./utils";
import { dropDownValuesAction } from "../../store/actions/dropDownValuesAction";
import "./AgilityList.scss";
import CommonAutocomplete from "../../Utils/CommonAutocomplete";
import ACPFilter from "../ACP/common/ACPFilter";
import CICInfo from "../../assets/images/CICInfo.svg";
import { Tooltip } from "primereact/tooltip";
import { cloneDeep, isArray, debounce } from "lodash";
import CustomInput from "../common/CustomInput";

const AgilityList = ({
  selected,
  dsbpPmpData,
  setSelected,
  selectAllChecked,
  handleSelect,
  handleSelectAll,
  onSort,
  selectedFields,
  onGlobalFilterChange,
  filteredDsbpData,
  setDsbpPmpData,
  onActionSubmit,
  buWiseSortedColumnNames,
  handleTabPanel,
  tabPanel,
  setFieldUpdated,
  fieldUpdated,
  setSavedData,
  addSavedData,
  handleYesAddToPRoject,
  setHandleYesAddToPRoject,
  rejectDialog,
  setRejectDialog,
  tableRender,
  setTableRender,
  customizeViewFields,
  setCustomizeViewFields,
  clearColumnWiseFilter,
  selectedReason,
  setSelectedReason,
  projectData,
  ownerList,
  ownerMatch,
  setResizeMode,
  resizeMode,
  sortData,
  setSortData,
  saveSettings,
  selectedColumnName,
  setSelectedColumnName,
  setFilteredDsbpData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { ProjectID } = useParams();

  const { artWorkTabValuesData } = useSelector((state) => state.ArtWorkTabValuesReducer);
  const { userInformation } = useSelector((state) => state.UserReducer);

  const currentUrl = useLocation().pathname.split("/");
  const accessSecurityMatrix = projectData?.Assignee === userInformation?.userid;

  const op = useRef(null);

  const [onChangeData, setOnChangeData] = useState(false);
  const [rejectFormData, setRejectFormData] = useState({});
  const [addedDataForSave, setAddedDataForSave] = useState([]);
  const [mismatchedOwnerRows, setMismatchedOwnerRows] = useState([]);
  const [width, setWidth] = useState(0);
  const [copyDsbpPmpData, setCopyDsbpPmpData] = useState(cloneDeep(dsbpPmpData));
  const [isDragging, setIsDragging] = useState(false);
  const dragStartXRef = useRef(0); // Use useRef for dragStartX
  const [arrowPosition, setArrowPosition] = useState("left"); // Default arrow position
  const frozenCoulmns = (typeof customizeViewFields === "string" && customizeViewFields ? JSON.parse(customizeViewFields)?.freezedColumns?.fieldsData : []);

  const actionNameObject = [
    {
      value: "Mass Update",
      key: "option-1",
      header: "Mass Update",
    },
    {
      value: "Create POAA",
      key: "option-2",
      header: "Are you sure you want to create POAs for below PMPs in RTA ?",
    },
    {
      value: "Group PMPs",
      key: "option-3",
      header: "Are you sure you want to group these PMPs ?",
    },
    {
      value: "Add to Project",
      key: "option-4",
      header: "Are you sure you want to add these PMP to Project ?",
    },
  ];
  const [tabsList, setTabsList] = useState([
    { tabHeader: "Header 1", decription: "Header 1 data" },
  ]);

  const allBUAttributesData = useSelector((state) => state.DropDownValuesReducer);
  const allBUAttributes = allBUAttributesData.DropDownValuesData;
  let aiseList = allBUAttributes?.ArtworkAgilityTasksPage?.Artwork_Alignment?.AISE;
  let assemblyMechanismList =
    allBUAttributes?.ArtworkAgilityTasksPage?.Artwork_Alignment?.Assembly_Mechanism;

  const optionsList = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
  ];

  const addToProjectList = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
    { name: "Reject", code: "Reject" },
  ];

  const addToProjectListYes = [{ name: "Yes", code: "Yes" }];

  const BU = projectData?.BU;
  // check whether project is from home care or baby care
  let isBUHomeCare = false;
  if (BU === "Home Care") {
    isBUHomeCare = true;
  }
  const dropdownRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (dropdownRef?.current) {
        setWidth(dropdownRef?.current?.offsetWidth);
      }
    }, 1000);
  }, [])
  useEffect(() => {
    setCustomizeViewFields(customizeViewFields);

    let jsonValue;
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }
    if (jsonValue && Object.keys(jsonValue).length !== 0) {
      let selectedData = jsonValue?.selectedFields?.fieldsData || [];
      let freezedData = jsonValue?.freezedColumns?.fieldsData || [];

      const totalColumns = [...freezedData, ...selectedData];

      if (totalColumns.length < 7) {
        setResizeMode("fit");
      } else {
        setResizeMode("expand");
      }

    }
  }, [customizeViewFields]);

  useEffect(() => {
    setSelectedColumnName("");
  }, [dsbpPmpData]);

  useEffect(() => {
    if (allBUAttributes === null) {
      (async () => {
        const allDropDown = await getDropDownValues();
        dispatch(dropDownValuesAction(allDropDown));
      })();
    }
  }, [allBUAttributes]);

  const onchangeAddToProject = (rowData, e, ele) => {
    setOnChangeData(rowData);
    if (e.target.value === "Reject") setRejectDialog(true);
    setRejectFormData({});
    if (e.target.value === "Yes") setHandleYesAddToPRoject(true);
    if (e.target.value === "No") {
      onChangeSelectField(rowData, e, ele);
    }
  };
  const onchangeOwner = (rowData, e, ele, index) => {
    if (!e.value || e.value === "") {
      ownerMatch(true);
      onChangeSelectField(rowData, e, ele);
      setMismatchedOwnerRows(mismatchedOwnerRows?.filter((idx) => idx !== index));
      return;
    }
    setOnChangeData(rowData);
    onChangeSelectField(rowData, e, ele);
    if (ownerList?.some((item) => item?.Person.includes(e?.value?.Person))) {
      ownerMatch(true);
      if (mismatchedOwnerRows?.includes(index)) {
        setMismatchedOwnerRows(mismatchedOwnerRows?.filter((idx) => idx !== index));
      }
    } else {
      ownerMatch(false);
      if (e.value !== "" && e.value !== null) {
        if (!mismatchedOwnerRows?.includes(index)) {
          setMismatchedOwnerRows([...mismatchedOwnerRows, index]);
        }
      } else {
        setMismatchedOwnerRows(mismatchedOwnerRows?.filter((idx) => idx !== index));
      }
    }
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const concatenatedFPCStagingFormula = (data) => {
    const concatenatedData = data.reduce((result, item) => {
      for (const key in item) {
        if (result.hasOwnProperty(key)) {
          result[key] += `,${item[key]}`;
        } else {
          result[key] = item[key];
        }
      }
      return result;
    }, {});
    return concatenatedData;
  };

  const onHandlePmpTabView = (options, field) => {
    const selectedTab = {
      tabHeader: options[field],
      description: options,
    };
    let updatedTabsList = [];
    if (tabsList.some((tab) => JSON.stringify(tab) === JSON.stringify(selectedTab))) {
      // selectedTab is already present
    } else {
      updatedTabsList = [...tabsList, selectedTab];
    }

    const newArray = Array.isArray(artWorkTabValuesData)
      ? [...updatedTabsList, ...artWorkTabValuesData]
      : updatedTabsList;

    // const uniqueArray = Array.from(
    //   new Set(newArray.map((obj) => JSON.stringify(obj)))
    // ).map(JSON.parse);

    const seenHeaders = new Set();
    const uniqueData = [];

    for (const item of newArray) {
      if (!seenHeaders.has(item.tabHeader)) {
        seenHeaders.add(item.tabHeader);
        uniqueData.push(item);
      }
    }
    const headerIndex = uniqueData.findIndex((item) => item.tabHeader === "Header 1");
    const headerObject = uniqueData[headerIndex];
    // Remove the object with "tabHeader": "Header 1" from the original array
    uniqueData.splice(headerIndex, 1);
    // Reverse the remaining data
    uniqueData.reverse();
    // Add the header object back to the beginning
    uniqueData.unshift(headerObject);
    dispatch(ArtWorkTabValuesAction(uniqueData));
    navigate(`/${currentUrl[1]}/DSBP/tab/artworkAlignment/${ProjectID}`, {
      replace: true,
    });
  };

  const onChangeSelectField = (option, e, field) => {
    if (typeof e === "object" && !Object.keys(e).length) return;
    const value = e?.target?.value;
    option[field] = value;
    if (field === "Owner" && value !== "") {
      option[field] = option?.Owner?.Person || "";
    }
    if (value === "" || value === null) {
      option[field] = ""; // Reset to empty value
    }
    setFieldUpdated(!fieldUpdated);

    const foundDsbpPmpData = copyDsbpPmpData?.find(
      (ele) => ele?.DSBP_PMP_PIMaterialID === option?.DSBP_PMP_PIMaterialID
    );

    // If value didn't change, skip update
    if (foundDsbpPmpData && foundDsbpPmpData[field] === value) return;
      if (addSavedData && addSavedData?.length) {
      // Update existing data if it exists
        addSavedData.map((ele) => {
          if (
            ele.DSBP_InitiativeID === option.DSBP_InitiativeID &&
            ele.DSBP_PMP_PIMaterialID === option.DSBP_PMP_PIMaterialID
          ) {
          ele[field] = value;
            if (field === "Owner") {
              ele[field] = ele?.Owner?.Person;
          }
            }
            return ele;
        });
      }
      let checkDataIsPresentOrNot = addSavedData?.filter(
        (ele) =>
          ele.DSBP_InitiativeID === option.DSBP_InitiativeID &&
          ele.DSBP_PMP_PIMaterialID === option.DSBP_PMP_PIMaterialID
      );
      if (!checkDataIsPresentOrNot.length) {
      let updatedData = {
        DSBP_InitiativeID: option?.DSBP_InitiativeID,
        DSBP_PMP_PIMaterialID: option?.DSBP_PMP_PIMaterialID,
        DSBP_PMP_PIMaterialNumber: option?.DSBP_PMP_PIMaterialNumber,
        FK_AWMProjectID: ProjectID,
        AWM_AddedToProject: option?.AWM_AddedToProject || "",
        AWM_AISE: option?.AWM_AISE || "",
        AWM_AssemblyMechanism: option?.AWM_AssemblyMechanism || "",
        AWM_Biocide: option?.AWM_Biocide || "",
        AWM_GroupPMP: option?.AWM_GroupPMP || "",
        AWM_Sellable: option?.AWM_Sellable || "",
        ReasonforRejection: option?.ReasonforRejection || "",
        RejectionComment: option?.RejectionComment || "",
        Owner: option?.Owner?.Person || "",
        [field]: value || "",
      };

        addSavedData.push(updatedData);
      }

    // debouncefunction(addSavedData);
    setSavedData([...addSavedData]);
  };

  // on call of owner change, first check weather that owner is present or not in the ownerList, then call the function
  const handleSelectOwner = (rowData, e, ele, i) => {
    if (ownerList?.includes(e?.target?.value)) {
      debouncefunction(rowData, e, ele, i);
    } else {
      return;
    }
  };

  const debouncefunction = useCallback(
    debounce((rowData, e, ele, i) => {
      onChangeSelectField(rowData, e, ele, i);
    }, 1000),
    [setSavedData]
  );

  // const debouncefunction = useCallback(
  //   debounce((addSavedData) => {
  //     setSavedData([...addSavedData]);
  //   }, 100),
  //   [setSavedData]
  // );

  const addBody = (options, rowData) => {
    let field = rowData.field;
    const showRedIcon = checkIfRedIcon(options);
    const showGreyIcon = checkIfGreyIcon(options);
    const showAlertIcon = showRedIcon || showGreyIcon;
    const fieldEditable = options["AWM_AddedToProject"] === "Yes";
    const ownerEditable = options["DSBP_PMP_AWReadinessGateStatus"] === dsbpConstant.xml_locked;
    const isOwner = options["Owner"] === userInformation?.userid;
    const addToProjectEditable =
      options["AWM_POARequested"] === "Yes" ||
      options["RTA_POANumber"] !== "" ||
      options["POAStatus"] === "Awaiting Response" ||
      options["DSBP_PMP_AWReadinessGateStatus"] !== "LOCKED" ||
      (options["RTA_POANumber"] === "" && options["AWM_CICNeeded"] === "Yes");

    // const addToProjectNotEditable =
    //   (options["AWM_POARequested"] === "Yes" && options["RTA_POANumber"] !== "") ||
    //   (options["AWM_POARequested"] === "Yes" &&
    //     options["RTA_POANumber"] == "" &&
    //     options["POAStatus"] == "Awaiting Response");
    let FPCStagingFormula = options?.FPCStagingPage?.[0]?.FormulaCardStagingPage;
    let concatenatedFPCStagingFormulaData = {};
    if (FPCStagingFormula && FPCStagingFormula.length) {
      concatenatedFPCStagingFormulaData = concatenatedFPCStagingFormula(FPCStagingFormula);
    }
    return (
      <>
        {field === "field_0" && ( // Add this condition to render a checkbox
          <div className="flex align-items-center">
            <input
              type="checkbox"
              className="p-checkbox-box p-highlight"
              checked={
                Array.isArray(selected) &&
                selected?.some(
                  (item) => item?.DSBP_PMP_PIMaterialID === options?.DSBP_PMP_PIMaterialID
                )
              }
              onChange={() => handleSelect(options)}
              disabled={!accessSecurityMatrix && !isOwner}
            />
          </div>
        )}
        {options?.FPCStagingPage?.[0][field] && (
          <span title={options?.FPCStagingPage?.[0][field]}>
            {options?.FPCStagingPage?.[0][field]}{" "}
          </span>
        )}
        {concatenatedFPCStagingFormulaData?.[field]}
        {field === "DSBP_PMP_PIMaterialNumber" && (
          <>
            <a className="tabView" onClick={() => onHandlePmpTabView(options, field)}>
              <span className="text-option" title={options[field]}>
                {options[field]}
              </span>
            </a>
            <AlertIcon
              iconType="error"
              showAlertIcon={showAlertIcon}
              onClick={() => {
                navigate(`/${currentUrl[1]}/artworkAlignment/ChangeNotification/${ProjectID}`, {
                  state: {
                    from: "artworkalignmentpage",
                    assignee: userInformation?.userid,
                    InitiativeID: options?.DSBP_InitiativeID,
                    PIMaterialID: options?.DSBP_PMP_PIMaterialID,
                    PIMaterialNumber: options?.DSBP_PMP_PIMaterialNumber,
                  },
                });
              }}
              editable={showGreyIcon}
              tooltipClass="tooltip-white"
              tooltipText={
                showGreyIcon
                  ? dsbpConstant.xml_awaiting_response_enovia
                  : dsbpConstant.xml_view_changes
              }
              tooltipPosition={"top"}
            />
          </>
        )}
        {field === "AWM_AddedToProject" && (
          <Form.Group controlId="groupName.ControlInput1" className="AddToProjectColumn">
            <Form.Select
              placeholder="Select"
              value={options[field]}
              onChange={(e) => onchangeAddToProject(options, e, field)}
              style={{ width: "80%", fontSize: 12 }}
              disabled={addToProjectEditable || !accessSecurityMatrix}
            >
              <option value="">Select</option>
              {options["AWM_POARequested"] === "Yes" && options["AWM_AWJStatus"] === "Active"
                ? addToProjectListYes?.map((data) => (
                  <option key={data.code} value={data.name}>
                    {data.name}
                  </option>
                ))
                : addToProjectList?.map((data) => (
                  <option key={data.code} value={data.name}>
                    {data.name}
                  </option>
                ))}
            </Form.Select>
            {options["RTA_POANumber"] === "" && options["AWM_CICNeeded"] === "Yes" && (
              <span>
              <img src={CICInfo} className="cicinfoicon" alt="" />
              <Tooltip
                position="top"
                target=".cicinfoicon"
                className="tooltip-white-cic"
                content={dsbpConstant.addToProjectDisabledMessage}
              />
              </span>
            )}
          </Form.Group>
        )}
        {field === "Owner" && (
          <Form.Group controlId="groupName.ControlInput1" className="owner-field-group">
            <div className="common-owner-field" ref={dropdownRef}>
              <CommonAutocomplete
                width={width}
                suggestions={ownerList}
                className="artworkOwnerField"
                value={options[field]}
                onChange={(e) => {
                  // onchangeOwner(options, e, field, rowData.rowIndex);
                  handleSelectOwner(options, e, field, rowData.rowIndex);
                }}
                placeholder="Select Shell Owner"
                field="Person"
                disabled={
                  !ownerEditable || !accessSecurityMatrix || options["AWM_AddedToProject"] !== "Yes"
                }
              />
              {mismatchedOwnerRows.includes(rowData.rowIndex) && <div>No results found</div>}
            </div>
          </Form.Group>
        )}
        {field === "AWM_AISE" && (
          <Form.Group controlId="groupName.ControlInput1" className="AISE-field-group">
            <Form.Select
              placeholder="Select"
              value={options[field]}
              disabled={!fieldEditable || !accessSecurityMatrix}
              onChange={(e) => onChangeSelectField(options, e, field)}
              style={{ width: "80%", fontSize: 12 }}
            >
              <option value="">Select</option>
              {aiseList?.map((data) => (
                <option key={data.AWM_AISE} value={data.AWM_AISE}>
                  {data.AWM_AISE}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        {field === "AWM_AssemblyMechanism" && (
          <Form.Group controlId="groupName.ControlInput1" style={{ textAlign: "-webkit-center" }}>
            <Form.Select
              placeholder="Select"
              value={options[field]}
              disabled={!fieldEditable || !accessSecurityMatrix}
              onChange={(e) => onChangeSelectField(options, e, field)}
              style={{ width: "80%", fontSize: 12 }}
            >
              <option value="">Select</option>
              {assemblyMechanismList?.map((data) => (
                <option key={data.code} value={data.AWM_AssemblyMechanism}>
                  {data.AWM_AssemblyMechanism || options[field]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        {field === "AWM_GroupPMP" && (
          <>
            {/* <Form.Group controlId="groupName.ControlInput1" style={{ textAlign: "-webkit-center" }}>
            <Form.Control
              type="text"
              value={options[field]}
              disabled={!fieldEditable || !accessSecurityMatrix}
              onChange={(e) => onChangeSelectField(options, e, field)}
              placeholder="Enter Group Name"
            />
          </Form.Group> */}
            {/* {commented above code as this block is inside loop, 
            so inside loop we can't compare and update value, 
            it will take lot of time, so avoid time delay, 
            created a seperate Reusable component, so that 
            it could be utilize in Future As well} */}
            <CustomInput
              type="text"
              field={field}
              options={options}
              value={options[field]}
              onChange={debouncefunction}
              placeholder={"Enter Group Name"}
              disabled={!fieldEditable || !accessSecurityMatrix}
            />
          </>
        )}

        {field === "AWM_Sellable" && (
          <Form.Group controlId="groupName.ControlInput1" style={{ textAlign: "-webkit-center" }}>
            <Form.Select
              placeholder="Select"
              value={options[field]}
              disabled={!fieldEditable || !accessSecurityMatrix}
              onChange={(e) => onChangeSelectField(options, e, field)}
              style={{ width: "80%", fontSize: 12 }}
            >
              <option value="">Select</option>
              {optionsList?.map((data) => (
                <option key={data.code} value={data.name}>
                  {data.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        {field === "AWM_Biocide" && (
          <Form.Group controlId="groupName.ControlInput1" style={{ textAlign: "-webkit-center" }}>
            <Form.Select
              placeholder="Select"
              value={options[field]}
              disabled={!fieldEditable || !accessSecurityMatrix}
              onChange={(e) => onChangeSelectField(options, e, field)}
              style={{ width: "80%", fontSize: 12 }}
            >
              <option value="">Select</option>
              {optionsList?.map((data) => (
                <option key={data.code} value={data.name}>
                  {data.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        {field == "RTA_POANumber" && <span>{options["RTA_POANumber"]}</span>}
        {field !== "AWM_AddedToProject" &&
          field !== "DSBP_PMP_PIMaterialNumber" &&
          field !== "AWM_AISE" &&
          field !== "AWM_AssemblyMechanism" &&
          field !== "AWM_Biocide" &&
          field !== "AWM_GroupPMP" &&
          field !== "AWM_Sellable" &&
          field !== "field_0" &&
          field !== "POA_POANumber" &&
          field !== "RTA_POANumber" &&
          field !== "POANumber" &&
          field !== "Owner" && <span title={options[field]}> {options[field]} </span>}
      </>
    );
  };

  const renderHeader = (field, col) => {
    let isFilterActivated =
      col?.freeze === true || col?.sortAtoZ === true || col?.sortZtoA === true;
    if (field === "checkbox") {
      // Render checkbox in header
      return (
        <div className="flex align-items-center">
          {/* 3190 changes and arrow left and right direction */}
          {isDragging && (
            <div
              className={`drag-arrow ${arrowPosition === "left" ? "left-arrow" : "right-arrow"}`}
            >
              {arrowPosition === "left" ? "←" : "→"}
            </div>
          )}
          <input
            type="checkbox"
            className="p-checkbox-box p-highlight"
            checked={selectAllChecked}
            disabled={dsbpPmpData === null || !accessSecurityMatrix}
            onChange={handleSelectAll}
          />
        </div>
      );
    }
    let splittedCol = col?.Column_Name?.split("_").join(" ");
    // splittedCol= splittedCol ==="Owner" ? "Shell Creation Owner":splittedCol;
    return (
      <div className="flex-display" key={field}>
        <img
          src={filter}
          key={field}
          alt="Column Filter"
          onClick={(e) => projectNameOnClick(e, field)}
          className={
            sortData?.includes(field) ||
              selectedFields?.[field]?.[0] ||
              selectedFields?.[field]?.[0] == false ||
              frozenCoulmns?.includes(field)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        />
        <span> {splittedCol}</span>
      </div>
    );
  };

  const renderColumns = () => {
    let jsonColumnWidth = isBUHomeCare
      ? localStorage.getItem("columnWidthDSBPArtworkHomeCare")
      : localStorage.getItem("columnWidthDSBPArtworkBabyCare");
    let allColumns = JSON.parse(jsonColumnWidth); //datatableColumnFromPega
    // customize fields
    let jsonValue;
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }
    if (jsonValue && Object.keys(jsonValue).length !== 0) {
      let selectedData = jsonValue?.selectedFields?.fieldsData || [];
      let freezedData = jsonValue?.freezedColumns?.fieldsData || [];
      const filteredColumns = [];
      // Add freezedData columns in the specified order
      freezedData?.forEach((fieldName, index) => {
        const column = allColumns?.find((col) => col.Field_Name === fieldName);
        if (column) {
          column.freeze = true;
          filteredColumns.push(column);
        } else {
          let newColumn = {};
          newColumn.Sequence = index;
          newColumn.Attribute_Type = "Free Text";
          newColumn.Column_Name = fieldName;
          newColumn.Field_Name = fieldName;
          newColumn.freeze = true;
          newColumn.reorder = false;
          newColumn.sortAtoZ = false;
          newColumn.sortZtoA = false;
          newColumn.width = 250;
          newColumn.customizeViewField = true;
          filteredColumns.push(newColumn);
        }
      });
      // Add selectedData columns in the specified order
      selectedData?.forEach((fieldName, index) => {
        const column = allColumns?.find((col) => col.Field_Name === fieldName);
        if (column) {
          filteredColumns.push(column);
        } else {
          let newColumn = {};
          newColumn.Sequence = index.toString();
          newColumn.Attribute_Type = "Free Text";
          newColumn.Column_Name = fieldName;
          newColumn.Field_Name = fieldName;
          newColumn.freeze = false;
          newColumn.reorder = false;
          newColumn.sortAtoZ = false;
          newColumn.sortZtoA = false;
          newColumn.width = 250;
          newColumn.customizeViewField = true;
          filteredColumns.push(newColumn);
        }
      });
      if (filteredColumns?.length > allColumns?.length) {
        isBUHomeCare
          ? localStorage.setItem("columnWidthDSBPArtworkHomeCare", JSON.stringify(filteredColumns))
          : localStorage.setItem("columnWidthDSBPArtworkBabyCare", JSON.stringify(filteredColumns));
      }
      if (filteredColumns && filteredColumns.length) {
        return [
          // Checkbox column
          <Column
            key="checkbox"
            body={addBody}
            frozen={true}
            columnKey="checkbox"
            header={() => renderHeader("checkbox")}
            className="checkbox-column"
            headerClassName="checkbox-width"
            bodyClassName="checkbox-width"
          />,
          // Rest of the columns
          ...filteredColumns.map((field, index) => {
            //if its not home care and BU is baby care restricted these columns
            if (
              !isBUHomeCare &&
              (field.Field_Name === AWM_Biocide ||
                field.Field_Name === AWM_AISE ||
                field.Field_Name === AWM_Sellable)
            ) {
              return null;
            }
            return (
              <Column
                field={field.Field_Name}
                header={() => renderHeader(field.Field_Name, field)}
                frozen={field.freeze}
                headerClassName={field.freeze ? "font-bold frozen-width" : ""}
                body={addBody}
                key={field.Field_Name}
                columnKey={field.Field_Name}
                showFilterMenu={false}
                alignFrozen="left"
                filterField={field.Field_Name}
              />
            );
          }),
        ];
      }
    } else {
      const upatingData = allColumns?.filter((data) => !data.customizeViewField);
      if (upatingData) {
        isBUHomeCare
          ? localStorage.setItem("columnWidthDSBPArtworkHomeCare", JSON.stringify(upatingData))
          : localStorage.setItem("columnWidthDSBPArtworkBabyCare", JSON.stringify(upatingData));
      }
      const upatingDataAllColumns = upatingData ? upatingData : allColumns;
      if (upatingDataAllColumns && upatingDataAllColumns.length) {
        return [
          // Checkbox column
          <Column
            key="checkbox"
            body={addBody}
            frozen={true}
            columnKey="checkbox"
            className="checkbox-column"
            header={() => renderHeader("checkbox")}
            headerClassName="checkbox-width"
            bodyClassName="checkbox-width"
          />,
          // Rest of the columns
          ...upatingDataAllColumns.map((field, index) => {
            //if its not home care and BU is baby care restricted these columns
            if (
              !isBUHomeCare &&
              (field.Field_Name === AWM_Biocide ||
                field.Field_Name === AWM_AISE ||
                field.Field_Name === AWM_Sellable)
            ) {
              return null;
            }
            return (
              <Column
                field={field.Field_Name}
                header={() => renderHeader(field.Field_Name, field)}
                frozen={field.freeze}
                body={addBody}
                key={field.Field_Name}
                columnKey={field.Field_Name}
                showFilterMenu={false}
                alignFrozen="left"
                filterField={field.Field_Name}
                headerClassName={field.freeze ? "font-bold " : ""}
              />
            );
          }),
        ];
      }
    }
  };

  const onColumnResizeEnd = (event) => {
    let columnWidth = [];
    let jsonColumnWidth = isBUHomeCare
      ? localStorage.getItem("columnWidthDSBPArtworkHomeCare")
      : localStorage.getItem("columnWidthDSBPArtworkBabyCare");
    if (jsonColumnWidth) {
      columnWidth = JSON.parse(jsonColumnWidth);
    }
    if (columnWidth) {
      columnWidth.map((list) => {
        if (event.column.props.field === list.Field_Name) {
          list.width = event.element.offsetWidth;
        }
      });
    }
    // localStorage.setItem("columnWidthDSBPArtwork", JSON.stringify(columnWidth));

    isBUHomeCare
      ? localStorage.setItem("columnWidthDSBPArtworkHomeCare", JSON.stringify(columnWidth))
      : localStorage.setItem("columnWidthDSBPArtworkBabyCare", JSON.stringify(columnWidth));
    setFieldUpdated(!fieldUpdated);
    setTableRender(false);
  };

  //AWM-3190 Function to handle the start of a drag event
  const onDragStart = (event) => {
    dragStartXRef.current = event.clientX || event.pageX; // Capture initial drag position
    setIsDragging(true); // Enable dragging
  };

  const onDrag = (event) => {
    if (isDragging) {
      const currentX = event.clientX || event.pageX; // Get current drag position
      if (currentX > dragStartXRef.current) {
        // Dragging to the right
        if (arrowPosition !== "right") {
          console.log("Dragging to the right");
          setArrowPosition("right");
        }
      } else if (currentX < dragStartXRef.current) {
        // Dragging to the left
        if (arrowPosition !== "left") {
          setArrowPosition("left");
        }
      }
    }
  };

  const onDragEnd = () => {
    setIsDragging(false); // Disable dragging
    dragStartXRef.current = 0; // Reset drag start position
  };

  const storeReorderedColumns = (e) => {
    let columnNames = [];
    // let jsonColumnNames = localStorage.getItem("columnWidthDSBPArtwork");
    let jsonColumnNames = isBUHomeCare
      ? localStorage.getItem("columnWidthDSBPArtworkHomeCare")
      : localStorage.getItem("columnWidthDSBPArtworkBabyCare");
    if (jsonColumnNames) {
      columnNames = JSON.parse(jsonColumnNames);
    }
    const shiftedArray = [...columnNames]; // Create a copy of the array
    // Find the index of the element to be shifted
    if (e?.dragIndex !== -1) {
      const [removed] = shiftedArray.splice(e?.dragIndex, 1); // Remove the element from the array
      // shiftedArray.unshift(removed); // Place the removed element at the beginning of the array
      shiftedArray.splice(e?.dropIndex, 0, removed);
    }
    shiftedArray.map((ele, index) => {
      ele["Sequence"] = index;
      ele["reorder"] = true;
    });

    isBUHomeCare
      ? localStorage.setItem("columnWidthDSBPArtworkHomeCare", JSON.stringify(shiftedArray))
      : localStorage.setItem("columnWidthDSBPArtworkBabyCare", JSON.stringify(shiftedArray));
    // localStorage.setItem(
    //   "columnWidthDSBPArtwork",
    //   JSON.stringify(shiftedArray)
    // );
    setFieldUpdated(!fieldUpdated);
    setTableRender(false);
  };

  return (
    <>
      <DataTable
        dataKey="DSBP_PMP_PIMaterialID"
        key={tableRender ? `"DSBP_PMP_PIMaterialID" + timestamp` : ""}
        scrollable
        resizableColumns
        reorderableColumns
        onColumnResizeEnd={onColumnResizeEnd}
        onColReorder={storeReorderedColumns}
        columnResizeMode={resizeMode}
        responsiveLayout="scroll"
        value={filteredDsbpData && filteredDsbpData.length ? filteredDsbpData : dsbpPmpData}
        className="mt-3"
        selection={selected}
        onSelectionChange={(e) => setSelected(e.value)}
        rowClassName={(rowData) => (selected?.includes(rowData) ? "selected-row" : "")}
        onDragStart={onDragStart}
        onDrag={onDrag} // Add onDrag to track the drag movement
        onDragEnd={onDragEnd}
      >
        {renderColumns()}
      </DataTable>
      <ACPFilter
        op={op}
        onSort={onSort}
        sortData={sortData}
        setSortData={setSortData}
        saveSettings={saveSettings}
        pageName={"artworkAlignment"}
        selectedFields={selectedFields}
        selectAllColumnNotIncluded={true}
        projectData={dsbpPmpData}
        selectedColumnName={selectedColumnName}
        filters={filteredDsbpData}
        onGlobalFilterChange={onGlobalFilterChange}
        setFilters={setFilteredDsbpData}
        clearColumnWiseFilter={clearColumnWiseFilter}
        frozenCoulmns={frozenCoulmns}
      />
      {rejectDialog && (
        <DsbpCommonPopup
          actionHeader="Are you sure you want to reject this PMP?"
          dasbpDialog={rejectDialog}
          setDasbpDialog={setRejectDialog}
          rejectFormData={rejectFormData}
          onSubmit={() => onActionSubmit(rejectFormData, [onChangeData])}
          okButtonShow={false}
          deleteButtonShow={false}
          showCancel={true}
          submitButtonShow={false}
          yesButtonShow={true}
          disconnectButtonShow={true}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
        >
          <DsbpRejectDialog
            onChangeData={onChangeData}
            rejectFormData={rejectFormData}
            setRejectFormData={setRejectFormData}
            setSelectedReason={setSelectedReason}
          />
        </DsbpCommonPopup>
      )}
      {handleYesAddToPRoject && (
        <DsbpActionDialog
          actionHeader="Are you sure you want to add these PMP to Project ?"
          actionDialog={handleYesAddToPRoject}
          setActionDialog={setHandleYesAddToPRoject}
          onChangeData={onChangeData}
          rowData={onChangeData}
          onActionSubmit={onActionSubmit}
          ownersList={ownerList}
          actionNameObject={actionNameObject}
        />
      )}
    </>
  );
};

export default AgilityList;
