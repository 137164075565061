import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { TabView, TabPanel } from "primereact/tabview";
import { Form } from "react-bootstrap";
import "./index.scss";
import { Accordion } from "react-bootstrap";
import { checkIfGreyIcon, checkIfRedIcon } from "../utils";
import { ArtWorkTabValuesAction } from "../../../store/actions/ArtWorkTabValuesActions";
import {
  getDsbpPMPDetails,
  onSubmitDsbpAction,
  getArtworkAlignmentTabDetails,
} from "../../../apis/dsbpApi";
import DsbpCommonPopup from "../DsbpCommonPopup";
import DsbpRejectDialog from "../RejectDialog";
import DsbpActionDialog from "../DsbpActionDialog";
import FooterButtons from "../../AWMJobs/DesignJobs/FooterButtons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CustomHeaderComponent from "./CustomHeaderComponent";
import AlertIcon from "../../common/AlertIcon/AlertIcon";
import { dsbpConstant } from "../constant";
import { textFieldMaxLength } from "../../../constants/index";
import { CharacterLimit } from "../../common/CharacterLimit/CharacterLimit";
import isEmpty from "lodash/isEmpty";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import CICInfo from "../../../assets/images/CICInfo.svg";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";

const PMPSpecificTabView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { ProjectID } = useParams();
  const _currentUrl = useLocation().pathname;
  const currentUrl = useLocation().pathname.split("/");

  const { userInformation } = useSelector((state) => state.UserReducer);
  const { artWorkTabValuesData } = useSelector((state) => state.ArtWorkTabValuesReducer);
  const { DropDownValuesData } = useSelector((state) => state.DropDownValuesReducer);
  const projectPlan = useSelector((state) => state.ProjectPlanReducer);
  let assignee;
  let BU;
  projectPlan?.projectPlanDesign?.map((item) => {
    if (item.AWM_Task_ID.includes("SAA") && item.GroupName === "Input") {
      assignee = item.Assignee;
      BU = item.BU;
    }
  });
  const [accessSecurityMatrix, setSecurityMatrix] = useState(false);
  const [storesTabList, setStoresTabDataList] = useState(artWorkTabValuesData);
  const [filteredDataList, setFilteredDataList] = useState(artWorkTabValuesData);
  const [actionDropDownValues, setActionDropDownValues] = useState([]);
  const [tabPanelList, setTabPanelList] = useState(1);
  const [onChangeData, setOnChangeData] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [rejectFormData, setRejectFormData] = useState({});
  const [handleYesAddToPRoject, setHandleYesAddToPRoject] = useState(false);
  const [aiseList, setAISEList] = useState([]);
  const [assemblyMechanismList, setAssemblyMechanismList] = useState([]);
  const [addToProjectValue, setAddToProjectValue] = useState("");
  const [aiseName, setAISEName] = useState("");
  const [assemblyMechanismChange, setAssemblyMechanismChange] = useState("");
  const [bioside, setBioside] = useState("");
  const [groupName, setGroupName] = useState("");
  const [sellable, setSellable] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedTab, setSelectedTabData] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedReason, setSelectedReason] = useState(false);
  const [changedData, setChangedData] = useState([]);
  const [owner, setOwner] = useState("");
  const [ownersList, setOwnersList] = useState([]);
  const [ownerMisMatch, setOwnerMisMatch] = useState(false);

  const toast = useRef(null);

  const showPyMessage = (msg) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: msg,
      sticky: true,
    });
  };

  const navigateToDSBP = () => {
    navigate(`/${currentUrl?.[1]}/artworkAlignment/${assignee}/${ProjectID}`);
  };

  // check whether project is from home care or baby care
  let isBUHomeCare = false;
  if (BU === "Home Care") {
    isBUHomeCare = true;
  }

  const optionsList = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
  ];

  const addToProjectList = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
    { name: "Reject", code: "Reject" },
  ];

  const addToProjectListYes = [{ name: "Yes", code: "Yes" }];

  useEffect(() => {
    if (!isEmpty(artWorkTabValuesData)) {
      let DSBP_InitiativeID, DSBP_PMP_PIMaterialID, DSBP_PMP_PIMaterialNumber;

      if (
        artWorkTabValuesData?.[tabPanelList]?.description?.DSBP_InitiativeID &&
        artWorkTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialID &&
        artWorkTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialNumber
      ) {
        const {
          DSBP_InitiativeID: id,
          DSBP_PMP_PIMaterialID: materialID,
          DSBP_PMP_PIMaterialNumber: materialNumber,
        } = artWorkTabValuesData[tabPanelList].description;
        DSBP_InitiativeID = id;
        DSBP_PMP_PIMaterialID = materialID;
        DSBP_PMP_PIMaterialNumber = materialNumber;
      }

      setSelectedTabData(artWorkTabValuesData?.[tabPanelList]);
      fetchArtworkAlignmentTabDetails(
        DSBP_InitiativeID,
        DSBP_PMP_PIMaterialID,
        DSBP_PMP_PIMaterialNumber,
        ProjectID
      );
    }
  }, [tabPanelList]);

  useEffect(() => {
    if (DropDownValuesData) {
      setActionDropDownValues(DropDownValuesData?.ArtworkAgilityTasksPage.Artwork_Alignment || []);
    }
  }, [DropDownValuesData]);

  useEffect(() => {
    if (actionDropDownValues !== undefined && actionDropDownValues.length !== 0) {
      setAISEList(actionDropDownValues.AISE);
      setAssemblyMechanismList(actionDropDownValues.Assembly_Mechanism);
    }
  }, [actionDropDownValues]);

  useEffect(() => {
    setFilteredDataList(artWorkTabValuesData);
  }, [artWorkTabValuesData]);

  useEffect(() => {
    setTabPanelList(artWorkTabValuesData?.length - 1);
    dispatch(ArtWorkTabValuesAction(artWorkTabValuesData));
  }, []);

  useEffect(() => {
    if (tabPanelList >= storesTabList?.length) {
      setTabPanelList(storesTabList.length - 1);
    }
    storesTabList !== undefined && dispatch(ArtWorkTabValuesAction(storesTabList));
    setSelectedTabData(artWorkTabValuesData?.[tabPanelList]);
    if (!isEmpty(artWorkTabValuesData) && artWorkTabValuesData[tabPanelList]) {
      const selectedTabData = artWorkTabValuesData[tabPanelList];
      if (selectedTabData?.description !== undefined) {
        const {
          AWM_AddedToProject,
          AWM_AssemblyMechanism,
          AWM_AISE,
          AWM_Biocide,
          AWM_Sellable,
          AWM_GroupPMP,
        } = selectedTabData?.description;
        setAddToProjectValue(AWM_AddedToProject);
        setAssemblyMechanismChange(AWM_AssemblyMechanism);
        setAISEName(AWM_AISE);
        setBioside(AWM_Biocide);
        setSellable(AWM_Sellable);
        setGroupName(AWM_GroupPMP);
        setOwner(selectedTabData?.description?.Owner);
      }
    }
    setFormData({});
  }, [storesTabList, tabPanelList]);
  useEffect(() => {
    let assignee = JSON.parse(localStorage.getItem("projectData"));
    if (assignee?.Assignee == userInformation?.userid) {
      setSecurityMatrix(true);
    } else {
      setSecurityMatrix(false);
    }
  }, [ProjectID, selectedTab]);

  useEffect(() => {
    if (!isEmpty(artWorkTabValuesData)) {
      setSelectedTabData(artWorkTabValuesData[tabPanelList]);
      setAddToProjectValue("");
      if (!isEmpty(artWorkTabValuesData[tabPanelList])) {
        const selectedTabData = artWorkTabValuesData[tabPanelList];
        if (selectedTabData?.description !== undefined) {
          const {
            AWM_AddedToProject,
            AWM_AssemblyMechanism,
            AWM_AISE,
            AWM_Biocide,
            AWM_Sellable,
            AWM_GroupPMP,
          } = selectedTabData?.description;
          setAddToProjectValue(AWM_AddedToProject);
          setAssemblyMechanismChange(AWM_AssemblyMechanism);
          setAISEName(AWM_AISE);
          setBioside(AWM_Biocide);
          setSellable(AWM_Sellable);
          setGroupName(AWM_GroupPMP);
          setOwner(selectedTabData?.description?.Owner);
        }
      }
    }
    setFormData({});
  }, [artWorkTabValuesData]);

  useEffect(() => {
    if (artWorkTabValuesData) {
      setStoresTabDataList(artWorkTabValuesData || []);
    }
  }, [artWorkTabValuesData]);

  const onchangeAddToProject = (rowData, e, ele) => {
    rowData[ele] = e.target.value;
    setOnChangeData(rowData);
    if (e.target.value === "Reject") setRejectDialog(true);
    setRejectFormData({});
    if (e.target.value === "Yes") setHandleYesAddToPRoject(true);
    if (e.target.value === "No") {
      setAddToProjectValue(e.target.value);
      setFormData({
        ...formData,
        AWM_AddedToProject: e.target.value,
      });
    }
  };

  const onChangeOwner = (e) => {
    setOwner(e.target.value);
    if (e?.target?.value) {
      if (!ownersList?.some((item) => item?.Person?.includes(e?.target?.value?.Person))) {
        setOwnerMisMatch(true);
      } else {
        setOwnerMisMatch(false);
      }
    } else {
      setOwnerMisMatch(false);
    }
    setFormData({
      ...formData,
      Owner: e.target.value,
    });
  };

  const handleAiseChange = (e) => {
    setAISEName(e.target.value);
    setFormData({
      ...formData,
      AWM_AISE: e.target.value,
    });
  };
  const handleAssemblyMechanismChange = (e) => {
    setAssemblyMechanismChange(e.target.value);
    setFormData({
      ...formData,
      AWM_AssemblyMechanism: e.target.value,
    });
  };

  const handleBiosideChange = (e) => {
    setBioside(e.target.value);
    setFormData({ ...formData, AWM_Biocide: e.target.value });
  };

  const handleSellableChange = (e) => {
    setSellable(e.target.value);
    setFormData({ ...formData, AWM_Sellable: e.target.value });
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
    setFormData({
      ...formData,
      AWM_GroupPMP: e.target.value,
    });
  };

  const handleCancel = () => {
    return navigateToDSBP();
  };

  const updateArtWorkTabValuesData = (updatedNewData) => {
    let submittionData = {};
    submittionData = {
      tabHeader: selectedTab.tabHeader,
      description: updatedNewData && updatedNewData[0],
    };
    const indexToUpdate = artWorkTabValuesData.findIndex(
      (tab) => tab.tabHeader === submittionData.tabHeader
    );
    if (indexToUpdate !== -1) {
      // Create a copy of the artWorkTabValuesData array
      const updatedArtWorkTabValuesData = [...artWorkTabValuesData];

      // Replace the element at the index with the selectedTab object
      updatedArtWorkTabValuesData[indexToUpdate] = submittionData;

      // Update the state with the updated array
      setStoresTabDataList(updatedArtWorkTabValuesData);
    }
  };

  useEffect(() => {
    async function fetchDetails() {
      const getDsbpPMPDetailData = await getDsbpPMPDetails(ProjectID);

      if (getDsbpPMPDetailData?.ownersList && getDsbpPMPDetailData.ownersList.length > 0) {
        setOwnersList(getDsbpPMPDetailData?.ownersList);
      }
      if (getDsbpPMPDetailData?.specialProjectFlag) {
        const specialProjectFlag = getDsbpPMPDetailData?.specialProjectFlag;
        dispatch(SpecialProjectAction(specialProjectFlag));
        redirectUnallocatedLinksToArtworkStatus(_currentUrl, specialProjectFlag, navigate);
      }
    }

    fetchDetails();
  }, [ProjectID]);

  const onSubmit = async (rejectFormData) => {
    setLoader(true);
    let updatedData = {};
    const selectionFormData = rejectFormData ? rejectFormData : formData;
    updatedData = {
      DSBP_InitiativeID: selectedTab?.description.DSBP_InitiativeID,
      DSBP_PMP_PIMaterialID: selectedTab?.description.DSBP_PMP_PIMaterialID,
      DSBP_PMP_PIMaterialNumber: selectedTab?.description.DSBP_PMP_PIMaterialNumber,
      FK_AWMProjectID: ProjectID,
      Owner: selectedTab?.description?.Owner,
      AWM_AddedToProject: selectedTab?.description?.AWM_AddedToProject || "",
      AWM_AISE: selectedTab?.description?.AWM_AISE || "",
      AWM_AssemblyMechanism: selectedTab?.description?.AWM_AssemblyMechanism || "",
      AWM_Biocide: selectedTab?.description?.AWM_Biocide || "",
      AWM_GroupPMP: selectedTab?.description?.AWM_GroupPMP || "",
      AWM_Sellable: selectedTab?.description?.AWM_Sellable || "",
      ReasonforRejection: selectedTab?.ReasonforRejection || "",
      RejectionComment: selectedTab?.RejectionComment || "",
    };
    if (rejectFormData) {
      updatedData.AWM_AddedToProject = "Reject";
    }
    if (selectionFormData === "AddToProject") {
      updatedData.AWM_AddedToProject = "Yes";
      setHandleYesAddToPRoject(false);
    } else {
      updatedData = { ...updatedData, ...selectionFormData };
      if (selectionFormData?.Owner) {
        updatedData["Owner"] = selectionFormData.Owner.Person;
      }
    }
    setRejectDialog(false);
    const updatedPmpDetails = { ArtworkAgilityPMPs: [updatedData] };
    // updatedPmpDetails API for post call
    let submitDsbpResponse = await onSubmitDsbpAction(updatedPmpDetails);

    // getDsbpPMPDetails API for get call
    const getDsbpPMPDetailData = await getDsbpPMPDetails(ProjectID);

    if (Object.keys(getDsbpPMPDetailData)?.length !== 0) {
      const updatedNewData = getDsbpPMPDetailData?.resp?.flatMap((item) =>
        item.DSBP_PMP_PIMaterialIDPage?.map((person) => ({
          DSBP_InitiativeID: item.DSBP_InitiativeID,
          ...person,
        }))
      );
      const filteredIds = Array.from(
        new Set(
          updatedNewData
            .filter(
              (item) =>
                item.DSBP_PMP_PIMaterialNumber === selectedTab.description.DSBP_PMP_PIMaterialNumber
            )
            .map((item) => item)
        )
      );
      updateArtWorkTabValuesData(filteredIds);
    }
    setFormData({});
    setLoader(false);
    if (submitDsbpResponse?.data?.pyMessage) showPyMessage(submitDsbpResponse?.data?.pyMessage);
  };

  const fetchArtworkAlignmentTabDetails = async (
    InitiativeID,
    PIMaterialID,
    PIMaterialNumber,
    projectId,
    reqId
  ) => {
    setLoader(true);
    const { response } = await getArtworkAlignmentTabDetails(
      InitiativeID,
      PIMaterialID,
      PIMaterialNumber,
      projectId
    );
    if (response) {
      const data = [
        ...(response?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.PMPFPCFormulaData?.[0]?.FieldsList ?? []),
      ];
      let isChangedData = data
        .map((obj) => {
          if (obj["IsChanged"] == "True") {
            return obj;
          }
        })
        .filter((ele) => ele != null);

      setChangedData(isChangedData);
    }
    setLoader(false);
  };

  // const isChangedValue = (value) => {
  //   const hasMatch = Boolean(
  //     changedData.find((el) => {
  //       return el.FieldName == value;
  //     })
  //   );
  //   return hasMatch;
  // };

  const getLatestChangedValues = (changedData, value, item) => {
    const isFound = changedData?.some((el) => el.FieldName?.includes(value));
    return (
      <span>
        {isFound ? (
          <span className="text-red">{getLatestValue(changedData, value)}</span>
        ) : (
          <span>{item[value]}</span>
        )}
      </span>
    );
  };

  const isChangedValue = (value) => {
    const hasMatch = changedData?.map(el => el?.FieldName).includes(value);
    return hasMatch;
  };

  const getLatestValue = (changedData, value) => {
    const data = changedData?.filter((el) => {
      return el?.FieldName?.includes(value);
    });
    return data[0]?.FieldValue;
  };

  const renderData = (tabData) => {
    // let jsonColumnWidth = localStorage.getItem("columnWidthDSBPArtwork");
    let jsonColumnWidth = isBUHomeCare
      ? localStorage.getItem("columnWidthDSBPArtworkHomeCare")
      : localStorage.getItem("columnWidthDSBPArtworkBabyCare");

    let allColumns = JSON.parse(jsonColumnWidth);

    const convertedInObject = [tabData];
    if (allColumns && allColumns.length) {
      return allColumns.map((field, index) => {
        const value = field?.Field_Name;

        return convertedInObject.map((item) => {
          const showRedIcon = checkIfRedIcon(item);
          const showGreyIcon = checkIfGreyIcon(item);
          const showAlertIcon = showRedIcon || showGreyIcon;
          const fieldEditable = item && item["AWM_AddedToProject"] === "Yes";
          const addToProjectEditable =
            item["AWM_POARequested"] === "Yes" ||
            item["RTA_POANumber"] !== "" ||
            item["RTA_RTAPOAStatus"] == "Awaiting Response" ||
            item["DSBP_PMP_AWReadinessGateStatus"] !== "LOCKED" ||
            (item["RTA_POANumber"] === "" && item["AWM_CICNeeded"] === "Yes");
          const ownerEditable =
            item && item["DSBP_PMP_AWReadinessGateStatus"] === dsbpConstant.xml_locked;
          return (
            <tr key={item[value]}>
              <td className="columnWidth">
                {
                  // field?.Column_Name==="Owner"? "Shell Creation Owner" : field.Column_Name?.replaceAll('_', ' ')
                  field.Column_Name?.replaceAll("_", " ")
                }
              </td>
              <td>
                {field.Field_Name === "AWM_AddedToProject" && (
                  <Form.Group controlId="groupName.ControlInput1" className="AddToProjectColumn">
                    <Form.Select
                      value={addToProjectValue}
                      placeholder="Select"
                      onChange={(e) => onchangeAddToProject(tabData, e, field)}
                      style={{ fontSize: 12 }}
                      disabled={addToProjectEditable || !accessSecurityMatrix}
                    >
                      <option value="">Select</option>
                      {item["AWM_POARequested"] === "Yes"
                        ? addToProjectListYes?.map((data) => (
                            <option key={data.code} value={data.name}>
                              {data.name}
                            </option>
                          ))
                        : addToProjectList?.map((data) => (
                            <option key={data.code} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                    </Form.Select>
                    {item["RTA_POANumber"] === "" && item["AWM_CICNeeded"] === "Yes" && (
                      <span>
                        <img src={CICInfo} className="cicinfoicon" alt="" />
                        <Tooltip
                          position="top"
                          target=".cicinfoicon"
                          className="tooltip-white-cic"
                          content={dsbpConstant.addToProjectDisabledMessage}
                        />
                      </span>
                    )}
                  </Form.Group>
                )}
                {field.Field_Name === "Owner" && (
                  <Form.Group controlId="groupName.ControlInput1">
                    {/* <Form.Select
                      value={owner} 
                      onChange={(e) => onChangeOwner(e)}
                      // style={{ fontSize: 12 }}
                      className="owner-field-form"
                      disabled={!ownerEditable || !accessSecurityMatrix || addToProjectValue!=="Yes"}
                    >
                      {owner ? (
                        <option value={owner} hidden >
                          {owner}
                        </option>
                      ) : (
                        <option value="" hidden>Select Shell Creation Owner</option>
                      )}
                      {ownersList?.map((data) => (
                        <option key={data?.Person} value={data?.Person}>
                          {data?.Person}
                        </option>
                      ))}
                    </Form.Select> */}
                    <CommonAutocomplete
                      suggestions={ownersList}
                      value={owner}
                      onChange={(e) => onChangeOwner(e)}
                      placeholder="Select Shell Owner"
                      field="Person"
                      disabled={
                        !ownerEditable || !accessSecurityMatrix || addToProjectValue !== "Yes"
                      }
                      className="tabViewOwner"
                    />
                    {ownerMisMatch && <div> No results found </div>}
                  </Form.Group>
                )}
                {field.Field_Name === "AWM_AISE" && (
                  <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                    <Form.Select
                      value={aiseName}
                      placeholder="Select AISE"
                      onChange={handleAiseChange}
                      disabled={!fieldEditable || !accessSecurityMatrix}
                    >
                      <option value="">Select AISE</option>
                      {aiseList.map((aise) => (
                        <option key={aise.code} value={aise.AWM_AISE}>
                          {aise.AWM_AISE}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {field.Field_Name === "AWM_AssemblyMechanism" && (
                  <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                    <div>
                      <Form.Select
                        value={assemblyMechanismChange}
                        placeholder="Select Assembly Mechanism"
                        onChange={handleAssemblyMechanismChange}
                        disabled={!fieldEditable || !accessSecurityMatrix}
                      >
                        <option value="">Select Assembly Mechanism</option>
                        {assemblyMechanismList.map((aise) => (
                          <option key={aise.code} value={aise.AWM_AssemblyMechanism}>
                            {aise.AWM_AssemblyMechanism}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>
                )}
                {field.Field_Name === "AWM_Biocide" && (
                  <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                    <div>
                      <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                        <div>
                          <Form.Select
                            value={bioside}
                            placeholder="Select Bioside"
                            onChange={handleBiosideChange}
                            disabled={!fieldEditable || !accessSecurityMatrix}
                          >
                            <option value="">Select Bioside</option>
                            {optionsList.map((data) => (
                              <option key={data.code} value={data.name}>
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </div>
                  </Form.Group>
                )}
                {field.Field_Name === "AWM_GroupPMP" && (
                  <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                    <input
                      type="text"
                      maxLength={textFieldMaxLength}
                      className={
                        groupName?.length === 0
                          ? "form-control"
                          : groupName?.length >= textFieldMaxLength
                          ? "form-control"
                          : "form-control border-success"
                      }
                      placeholder="Enter Group Name"
                      onChange={handleGroupName}
                      value={groupName}
                      disabled={!fieldEditable || !accessSecurityMatrix}
                    />
                    <CharacterLimit field={groupName} maxLength={textFieldMaxLength} />
                  </Form.Group>
                )}
                {field.Field_Name === "AWM_Sellable" && (
                  <Form.Group className={`mb-2`} controlId="groupName.ControlInput1">
                    <div>
                      <Form.Select
                        value={sellable}
                        placeholder="Select Sellable"
                        onChange={handleSellableChange}
                        disabled={!fieldEditable || !accessSecurityMatrix}
                      >
                        <option value="">Select Sellable</option>
                        {optionsList.map((data) => (
                          <option key={data.code} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>
                )}

                {field.Field_Name == "DSBP_PMP_PIMaterialNumber" && (
                  <>
                    <span>
                      {isChangedValue("DSBP_PMP_PIMaterialNumber") ? (
                        <>
                          <span className="text-option text-red">
                          {getLatestValue(changedData,"DSBP_PMP_PIMaterialNumber",item)}{" "}
                          </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            onClick={() => {
                              navigate(
                                `/${currentUrl?.[1]}/DSBP/tab/artworkAlignment/ChangeNotification/${ProjectID}`,
                                {
                                  state: {
                                    from: "artworkalignmentTabpage",
                                    assignee: assignee,
                                    InitiativeID: item.DSBP_InitiativeID,
                                    PIMaterialID: item.DSBP_PMP_PIMaterialID,
                                    PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                                  },
                                }
                              );
                            }}
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      ) : (
                        <>
                          <span className="text-option">{item[value]} </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            onClick={() => {
                              navigate(
                                `/${currentUrl?.[1]}/DSBP/tab/artworkAlignment/ChangeNotification/${ProjectID}`,
                                {
                                  state: {
                                    from: "artworkalignmentTabpage",
                                    assignee: assignee,
                                    InitiativeID: item.DSBP_InitiativeID,
                                    PIMaterialID: item.DSBP_PMP_PIMaterialID,
                                    PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                                  },
                                }
                              );
                            }}
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      )}
                    </span>
                  </>
                )}

                {field.Field_Name !== "AWM_AddedToProject" &&
                  field.Field_Name !== "AWM_AISE" &&
                  field.Field_Name !== "AWM_AssemblyMechanism" &&
                  field.Field_Name !== "AWM_Biocide" &&
                  field.Field_Name !== "AWM_GroupPMP" &&
                  field.Field_Name !== "AWM_Sellable" &&
                  field.Field_Name !== "DSBP_PMP_PIMaterialNumber" &&
                  field.Field_Name !== "Owner" && (
                    <>{getLatestChangedValues(changedData, value, item)}</>
                  )}
              </td>
            </tr>
          );
        });
      });
    }
    return null; // return null if there are no columns or tabData is empty
  };

  const tabsCompo = (obj) => {
    const showGreyIcon = checkIfGreyIcon(obj?.description);
    const showRedIcon = checkIfRedIcon(obj?.description);
    const showAlertIcon = showRedIcon || showGreyIcon;
    return (
      <div className="tabsCompo">
        <div className="btn-right-wrapper">
          {showAlertIcon ? (
            <>
              <button
                className={`btn btn-right`}
                onClick={() => {
                  navigate(
                    `/${currentUrl?.[1]}/DSBP/tab/artworkAlignment/ChangeNotification/${ProjectID}`,
                    {
                      state: {
                        from: "artworkalignmentTabpage",
                        assignee: assignee,
                        InitiativeID: obj?.description?.DSBP_InitiativeID,
                        PIMaterialID: obj?.description?.DSBP_PMP_PIMaterialID,
                        PIMaterialNumber: obj?.description?.DSBP_PMP_PIMaterialNumber,
                      },
                    }
                  );
                }}
              >
                {dsbpConstant.xml_view_changes_btn}
              </button>
            </>
          ) : null}
        </div>
        <div className="tabsCompoMain">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Material Details</Accordion.Header>
              <Accordion.Body>
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description)}</tbody>
                  <br />
                  <br />
                  <br />
                  <br />
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  };
  const handleDelete = (index) => {
    const updatedDataList = [...storesTabList];
    updatedDataList.splice(index, 1);
    setStoresTabDataList(updatedDataList);
    if (tabPanelList >= storesTabList.length) {
      setTabPanelList(storesTabList.length - 1);
    }
  };

  const onTabChange = (index) => {
    setTabPanelList(index);
    setOwnerMisMatch(false);
    if (index === 0) {
      return navigateToDSBP();
    }
  };

  const renderTabs = () => {
    return filteredDataList.map((obj, index) => (
      <TabPanel
        key={index}
        header={
          <CustomHeaderComponent tabHeaderDetails={obj} index={index} handleDelete={handleDelete} />
        }
        scrollable
      >
        <>
          {loader ? (
            <LoadingOverlay active={true} spinner text="" />
          ) : (
            index !== 0 && tabsCompo(obj)
          )}
        </>
      </TabPanel>
    ));
  };

  return (
    <>
      <Toast ref={toast} />
      {artWorkTabValuesData?.length > 1 && tabPanelList !== 0 ? (
        <TabView
          activeIndex={tabPanelList}
          scrollable={
            (artWorkTabValuesData?.length > 3 ? true : false) ||
            (artWorkTabValuesData?.[tabPanelList]?.description.RTA_POADescription.length >= 168 &&
              true)
          }
          onTabChange={(e) => onTabChange(e.index)}
        >
          {renderTabs()} tabHeader
        </TabView>
      ) : (
        navigateToDSBP()
      )}
      {rejectDialog && (
        <DsbpCommonPopup
          actionHeader="Are you sure you want to reject this PMP?"
          dasbpDialog={rejectDialog}
          setDasbpDialog={setRejectDialog}
          rejectFormData={rejectFormData}
          onSubmit={() => onSubmit(rejectFormData)}
          okButtonShow={false}
          deleteButtonShow={false}
          showCancel={true}
          submitButtonShow={false}
          yesButtonShow={true}
          disconnectButtonShow={true}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
        >
          <DsbpRejectDialog
            onChangeData={onChangeData}
            rejectFormData={rejectFormData}
            setRejectFormData={setRejectFormData}
            setSelectedReason={setSelectedReason}
          />
        </DsbpCommonPopup>
      )}
      {handleYesAddToPRoject && (
        <DsbpActionDialog
          actionHeader="Are you sure you want to add these PMP to Project ?"
          actionDialog={handleYesAddToPRoject}
          setActionDialog={setHandleYesAddToPRoject}
          onChangeData={onChangeData}
          rowData={onChangeData}
          onActionSubmit={onSubmit}
          ownersList={ownersList}
        />
      )}
      <FooterButtons
        handleCancel={handleCancel}
        hideSaveButton={true}
        onSubmit={onSubmit}
        formValid={Object.keys(formData).length === 0 || ownerMisMatch}
        checkReadWriteAccess={!false}
        submitAndSave="Save"
        Assignee={assignee}
      />
    </>
  );
};

export default PMPSpecificTabView;
